import { Alert, AlertTitle, Slide, Snackbar } from "@mui/material"


const FailureNotification = ({OpenError, handleClose, ErrorMessage}) => {
    return(
        <Snackbar
        open={OpenError}
        autoHideDuration={2000}
        anchorOrigin={{ vertical:'top', horizontal:'center'}}
        onClose={handleClose}
        TransitionComponent={Slide}
        transitionDuration={{enter:1000, exit:2000}}
        TransitionProps={{enter: true, exit:false}}
        >
             <Alert severity="error" variant="filled" onClose={handleClose}>
                <AlertTitle>Error</AlertTitle> 
                <strong>{ErrorMessage}</strong>    
            </Alert> 
        </Snackbar>
    )
}

export default FailureNotification