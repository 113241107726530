import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import { Box } from "@mui/system";
import MainCard from "ui-component/cards/MainCard";
import { Grid } from '@mui/material';
import { Button, Stack, TextField } from "@mui/material";
import FailureNotification from "ui-component/FailureNotification";
import SuccessNotification from "ui-component/SuccessNotification";

function EditUser() {

    const { REACT_APP_API_ENDPOINT } = process.env

    const { UserID } = useParams()
    const [customProps, setCustomProps] = React.useState(null)
    const [baseProps, setBaseProps] = React.useState(null)
    const [required, setRequired] = React.useState(null)
    const [customRequired, setCustomRequired] = React.useState(null)
    const history = useNavigate();

    let validityObject = {};
   
    let initValue = {
        
    };

    function getErrorMsg(key, type, title) {
        if (type === 1) {
            //eslint-disable-next-line
            return (title + ' ' + 'is required')
        }
        else if (type === 2) {
            //eslint-disable-next-line
            return ('Atleast' + ' ' + key + ' ' + 'characters are required')
        }
        else if (type === 3) {
            //eslint-disable-next-line
            return ('Maximum' + ' ' + key + ' ' + 'characters are allowed')
        }
    }

    // const getApps = async () => {
    //     await axios
    //         .post(`${REACT_APP_API_ENDPOINT}/apps/get-apps`,
    //             {
    //                 "filterType": 2,
    //                 "value": "ACTIVE"
    //             }
    //         )
    //         .then((res) => {
    //             let appOptions = []
    //             let appOptionsRoles = []
    //             let DisableDefaultOktaApps = REACT_APP_OKTA_DISABLE_DEFAULT_APPS.split(',')
    //             res.data.data?.body.map((item) => {
    //                 if(!DisableDefaultOktaApps.includes(item.id)){
    //                     appOptions.push({
    //                         'label': item.label,
    //                         'id': item.id
    //                     })   
    //                 }
    //                 return item
    //             })
    //             // setAppArray(appOptions)
    //             if (adminFind) {
    //                 setAppArray(appOptions)
    //                 if(appOptions.length ===1){
    //                     formik.setFieldValue("appId", appOptions[0].id)
    //                 }
    //             } else {
    //                 roleID.map((value) => {
    //                     appOptions.map((item) => {
    //                         if (item.id === value) {
    //                             appOptionsRoles.push({
    //                                 'label': item.label,
    //                                 'id': item.id
    //                             })
    //                         }
    //                         return item
    //                     })
    //                     return value
    //                 })
    //                 setAppArray(appOptionsRoles)
    //                 // if(appOptionsRoles.length === 1){
    //                 //     console.log(appOptionsRoles[0].id)
    //                 //     formik.setFieldValue("appId", appOptionsRoles[0].id)
    //                 // }
    //             }
    //         }
    //         )
    //         .catch((err) => console.log(err))
    // }

    // React.useEffect(() => {
    //     getApps()
    //     //eslint-disable-next-line
    // }, [])

    const [userDetails, setUserDetails] = useState(null)

    const getUserDetails = async () => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}/users/get-user-detail/${UserID}`)
            .then((res) => {
                setUserDetails(res.data.data.profile)
            })
            .catch((err) => {
                    if(err.response.status === 429){
                        setopenError(true);
                        setErrorMessage('Too Many Requests')
                    }else{
                        console.log(err)
                    }
            })
    }

    useEffect(() => {
        getUserDetails()
        //eslint-disable-next-line
    }, [UserID])

    const getDefaultUserSchema = async () => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}/apps/get-default-user-schema`)
            .then((res) => {
                setBaseProps(res.data.definitions.base.properties)
                setRequired(res.data.definitions.base.required)
                setCustomProps(res.data.definitions.custom.properties)
                setCustomRequired(res.data.definitions.custom.required)
            })
            .catch((err) => { 
                if(err.response.status === 429){
                    setopenError(true);
                    setErrorMessage('Too Many Requests')
                }else{
                    console.log(err)
                }
             })
    }

    useEffect(() => {
        getDefaultUserSchema();
        //eslint-disable-next-line
    }, [])

    // const getUserSchemaByApp = async () => {
    //     await axios
    //         .get(`${REACT_APP_API_ENDPOINT}/apps/get-user-schema-by-app/${oktaAppID}`)
    //         .then((res) => {
    //             setCustomProps(res.data.definitions.custom.properties)
    //             setCustomRequired(res.data.definitions.custom.required)
    //         })
    //         .catch((err) => { console.log(err) })
    // }

    // useEffect(() => {
    //     if (oktaAppID) {
    //       //  getUserSchemaByApp()
    //     }
    //     //eslint-disable-next-line
    // }, [oktaAppID])

    let attributes = {};
    const [allRequired, setAllRequired] = React.useState([])

    customProps ? attributes = {
        ...baseProps,
        ...customProps
    }
        : attributes = baseProps

    useEffect(() => {
        customRequired ? setAllRequired(required?.concat(customRequired))
            :
            setAllRequired(required)
    }, [required, customRequired])

    // useEffect(() => {
    //     if (userDetails) {
    //         Object.entries(userDetails).map(([key, value]) => {
    //             if (value === null) {
    //                 delete userDetails.key
    //             }
    //             return value
    //         })
    //     }
    // }, [userDetails])

    if (userDetails) {
        Object.entries(userDetails).map(([key, value]) => {
            if (value === null) {

            } else {
                if (key === 'login') {

                } else if (value?.type === 'boolean') {
                    initValue = {
                        ...initValue,
                        [key]: value
                    }

                }
                else if (typeof (value) === 'object') {
                    if (value?.items?.oneOf && value?.items?.oneOf.length > 0) {
                        initValue = {
                            ...initValue,
                            [key]: value
                        }
                    }
                    else {
                        initValue = {
                            ...initValue,
                            [key]: value
                        }
                    }
                } else {
                    initValue = {
                        ...initValue,
                        [key]: value
                    }

                }

                if (key === "login") {

                } else if (key === "email") {
                    validityObject[key] = yup.string().required('Email is required').email('Email must be valid')
                } else if (typeof (value) === 'object' && value?.items?.enum && value?.items?.oneOf && value?.items?.enum.length > 0 && value?.items?.oneOf.length > 0) {
                    validityObject[key] = yup.array().of(yup.string().required("This field is Required"))
                } else if (typeof (value) === 'object') {
                    validityObject[key] = yup.array().of(yup.string().required("This field is Required"))
                } else if (typeof (value) === 'object' && value?.itemType === 'integer') {
                    validityObject[key] = yup.string().test("required", "please enter comma seperated integer values", (value) => {
                        if (value) {
                            let regex = /^[0-9]+(,?[0-9]+)*?$/g
                            let Data = value.match(regex);
                            if (Data) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    })

                } else if (value?.type === "integer") {
                    validityObject[key] = yup.string().test("required", "please Enter numbers only", (values) => {
                        if (values) {
                            let regex = /^[0-9]*$/
                            let Data = values.match(regex);
                            if (Data) {
                                return true;
                            } else {
                                return false;
                            }

                        }
                    })
                }
                else if (value?.type === 'array' && value?.itemType === 'string') {
                    validityObject[key] = yup.string().test("required", "please enter comma seperated string values", (value) => {
                        if (value) {
                            let regex = /^[a-zA-Z]+(,?[a-zA-Z]+)*?$/g
                            let Data = value.match(regex);
                            if (Data) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    })

                }
                else {
                    validityObject[key] =
                        yup.string()
                            .test("required", getErrorMsg(key, 1, value?.title), (val) => {
                                let requiredAtt = allRequired?.includes(key)
                                if (requiredAtt) {
                                    if (val) {

                                    } else {
                                        return false;
                                    }
                                }
                                return true
                            })
                            .test("minLength", getErrorMsg(value?.minLength, 2, value?.title), (val) => {
                                if (val && value?.minLength && value?.minLength > val.length) {
                                    return false;
                                }
                                return true;
                            })
                            .test("maxLength", getErrorMsg(value?.maxLength, 3, value?.title), (val) => {
                                if (val && value?.maxLength && value.maxLength < val.length) {
                                    return false;
                                }
                                return true;
                            })
                }

                return value
            }
            return value
        })


    }

    const validationSchema = yup.object().shape(validityObject)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initValue,
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {


            let object = {
                ...values
            }

            delete object.appId;

            if (attributes) {
                Object.entries(attributes).map(([key, value]) => {
                    if (value?.type === 'array' && !value?.items?.enum && !value?.items?.oneOf) {
                        if (object[value]) {
                            object[key] = object[key]?.split(',')
                        }
                    }
                    return value
                })
            }

            // console.log('object- ', object)

            updateUser(object)
        }
    })

    const updateUser = async (obj) => {
        await axios
            .post(`${REACT_APP_API_ENDPOINT}/users/update-user/${UserID}`, obj)
            .then((res) => {
                setOpenSuccess(true)
                setSuccessMessage(res.data.messgae)
                if (res.data.responseStatus === 'SUCCESS') {
                    history(`/user/list-users`)
                }
            }
            )
            .catch((err) => {
                setopenError(true);
                setErrorMessage("Request Failed")
            })
    }

    // console.log('error- ', formik.errors)

    // useEffect(() => {
    //     setOktaAppID(formik.values.appId)
    // }, [formik.values.appId])

    //********* || ALERTS  || ****************/

    const [openError, setopenError] = React.useState(false);
    const [ErrorMessage, setErrorMessage] = React.useState("Failed");
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [SuccessMessage, setSuccessMessage] = React.useState("Success");

    //***************** || Alert Handle Close  || ************************* */ 

    const handleCloseAlertError = () => {
        setErrorMessage("");
        setopenError(false);
    };

    const handleCloseAlertSuccess = () => {
        setSuccessMessage("");
        setOpenSuccess(false);
    };

    return (
        <>

            <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
            <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
            <MainCard title='Edit User' id="Main">
                <Box
                    component='form'
                    sx={{
                        '&.MuiTextField-root': { m: 0 },
                        flexGrow: 1
                    }}
                    onSubmit={formik.handleSubmit}
                >
                    <Grid container spacing={4}>

                        {/* <Grid item xs={6}>
                            <TextField
                                select
                                required
                                fullWidth
                                name='appId'
                                label='Application Name'
                                SelectProps={{ multiple: false }}
                                value={formik.values.appId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.appId && Boolean(formik.errors.appId)}
                                helperText={formik.touched.appId && formik.errors.appId}
                                
                            >
                                <MenuItem value='' style={{ display: 'none' }}>Please Select</MenuItem>
                                {appArray?.map((option) => (
                                    <MenuItem key={option.id + '_appID'} value={option.id}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid> */}

                        {attributes  ?

                            Object.entries(attributes).map(([key, value]) => {
                                if (key === 'login') {
                                    return <Grid key={key + '_login'}></Grid>
                                } else if (key === 'email') {
                                    return <Grid key={key + '_email'}></Grid>
                                } else if (value?.type === 'boolean') {
                                    let requiredAtt = allRequired?.includes(key)
                                    return (
                                        <Grid item xs={6} key={key + '_boolean'} style={{ paddingLeft: '92px', transform: 'scale(1.2)', paddingTop: '44px' }}>
                                            <input type='checkbox'
                                                required={requiredAtt}
                                                name={key}
                                                id={key}
                                                value={formik.values[key] || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            // error={formik.touched[key] && Boolean(formik.errors[key])}
                                            // helperText={formik.touched[key] && formik.errors[key]}
                                            />
                                            {value?.title}
                                            {formik.touched[key] &&
                                                formik.errors[key] ? (
                                                <div className="error-msg">
                                                    {formik.errors[key]}
                                                </div>
                                            ) : null}
                                        </Grid>

                                    )
                                }
                                else if (value?.type === 'array') {
                                    let requiredAtt = allRequired?.includes(key)
                                    if (value?.items?.oneOf && value?.items?.oneOf.length > 0 && value?.items?.enum && value?.items?.enum.length > 0) {
                                        return (
                                            <Grid item xs={6} key={key + '_arrayList'}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    name={key}
                                                    label={value?.title}
                                                    SelectProps={{ multiple: true }}
                                                    value={formik.values[key] || []}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[key] && Boolean(formik.errors[key])}
                                                    helperText={formik.touched[key] && formik.errors[key]}
                                                    required={requiredAtt}
                                                >
                                                    {value?.items?.oneOf.map((key, name) => {
                                                        return (
                                                            <MenuItem
                                                                key={key.const + "_multiselect"}
                                                                value={key.const}
                                                            >
                                                                {key.title}
                                                            </MenuItem>
                                                        )
                                                    }
                                                    )}
                                                </TextField>

                                            </Grid>
                                        )
                                    }
                                    else {
                                        return (
                                            <Grid item xs={6} key={key + "_array"}>
                                                <TextField
                                                    fullWidth
                                                    id={key}
                                                    name={key}
                                                    label={value?.title}
                                                    value={formik.values[key] || ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[key] && Boolean(formik.errors[key])}
                                                    helperText={formik.touched[key] && formik.errors[key]}
                                                    required={requiredAtt}
                                                >
                                                </TextField>
                                            </Grid>
                                        )
                                    }
                                }
                                else {
                                    let requiredAtt = allRequired?.includes(key)
                                    if (value?.enum && value?.enum.length > 0 && value?.oneOf && value?.oneOf.length > 0) {
                                        return (
                                            <Grid item xs={6} key={key + "_select"}>

                                                <TextField
                                                    select
                                                    fullWidth
                                                    name={key}
                                                    value={formik.values[key] || []}
                                                    onChange={formik.handleChange}
                                                    label={value?.title}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[key] && Boolean(formik.errors[key])}
                                                    helperText={formik.touched[key] && formik.errors[key]}
                                                    required={requiredAtt}
                                                >
                                                    {
                                                        value?.oneOf.map((item) => {
                                                            return (
                                                                <MenuItem
                                                                    key={item.const}
                                                                    value={item.title}
                                                                >
                                                                    {item.title}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </Grid>
                                        )
                                    }
                                    else {
                                        return (
                                            <Grid item xs={6} key={key + "_other"}>
                                                <TextField
                                                    fullWidth
                                                    id={key}
                                                    name={key}
                                                    label={value?.title}
                                                    value={formik.values[key] || ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched[key] && Boolean(formik.errors[key])}
                                                    helperText={formik.touched[key] && formik.errors[key]}
                                                    required={requiredAtt}
                                                >
                                                </TextField>
                                            </Grid>
                                        )
                                    }
                                }

                            })

                            : null}
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={6} style={{ margin: '30px 0px' }}>
                            <Stack spacing={1} direction="row">
                                <Button color="primary" variant="contained" type="submit" onClick={formik.handleSubmit}>
                                    Submit
                                </Button>
                                <Button color="primary" variant="outlined" type="button" onClick={formik.resetForm}>
                                    Clear
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{marginTop:"2px"}}>
                    <Grid item xs={6}>
                        <Stack spacing={1} direction="row" >
                            {/* <Button type="submit" variant="contained" color="primary">
                            Update
                            </Button> */}
                            <Button type="button" variant="outlined" color="primary" onClick={() => history(-1) }>
                            Back
                            </Button>
                        </Stack>
                    </Grid>
                    </Grid>
                </Box>
            </MainCard>
        </>
    )
}

export default EditUser;
