import * as React from 'react'
import { setNestedObjectValues, useFormik } from 'formik';
import {  useNavigate } from 'react-router-dom';
import {  useLocation } from 'react-router';
import * as yup from 'yup';
import axios from 'axios';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, TextField } from "@mui/material";
import {makeStyles} from "@mui/styles" 
import MenuItem from '@mui/material/MenuItem';
import { Box } from "@mui/system";
import MainCard from "ui-component/cards/MainCard";
import { useSelector } from "react-redux";
import FailureNotification from "ui-component/FailureNotification";
import SuccessNotification from "ui-component/SuccessNotification";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const useStyles = makeStyles({
    root: {
      padding: '0',
      backgroundColor: '#fdfcfc',
      marginTop: '1.5rem',
      border: 0,
    borderRadius: '20px',
    background: '#ffffff',
    boxShadow:  '5px 5px 0px #e9e9e9,-5px -5px 0px #ffffff',
    }
  });

function CreateUser() {
    const classes = useStyles();
    const routeState = useLocation();
    const [appArray, setAppArray] = React.useState([]);
    // const [allGroupsArray, setAllGroupsArray] = React.useState(null);
    // const [groupArray, setGroupArray] = React.useState(null);
    const [oktaAppID, setOktaAppID] = React.useState(routeState?.state?.applicationId?routeState?.state?.applicationId:null);
    const [passwordComplexity, setPaswordComplexity] = React.useState({});
    const [passwordRequired, setPasswordRequired] = React.useState(false)
    // const [groupIDSelected, setGroupIDSelected] = React.useState([]);
    const [userExist, setUserExist] = React.useState(true);
    const [AssignUser, setAssignUser] = React.useState(false)
    const [ createUser, setCreateUser] = React.useState(false)
    const [userId, setUserId] = React.useState(null);
    const [SettingRoles, setSettingRoles] = React.useState('')
    const history = useNavigate();
    //eslint-disable-next-line
    const [UpdatedRoles, setUpdatedRoles] = React.useState([])
  
    //********* || ALERTS  || ****************/

    const [openError, setopenError] = React.useState(false);
    const [ErrorMessage, setErrorMessage] = React.useState("");
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [SuccessMessage, setSuccessMessage] = React.useState("");
    const [groupLabelsArray, setGroupLabelsArray] = React.useState(null)

    //************ || ENVIRONMENT VARIABLE || ************* */


    const { REACT_APP_API_ENDPOINT, REACT_APP_OKTA_DISABLE_DEFAULT_APPS } = process.env
    const DisableDefaultOktaApps = REACT_APP_OKTA_DISABLE_DEFAULT_APPS.split(",")

    const state = useSelector(state => state);
    const roles = state.authUser.roles;

    let roleID = roles.map((role) => role.split("partneriam-")[1]);
    let adminFind = roleID.includes("admin");

    const getApps = async (searchValue) => {
        await axios
            .post(`${REACT_APP_API_ENDPOINT}/apps/get-apps?q=${searchValue}&limit=200`,
                {
                    "filterType": 2,
                    "value": "ACTIVE"
                }
            )
            .then((res) => {
                let appOptions = []
              
                res.data.data?.body.map((item) => {
                 if(!DisableDefaultOktaApps.includes(item.id)){
                    appOptions.push({
                        'label': item.label,
                        'id': item.id
                    })
                 }
                    return item
                })
                if (adminFind) {
                    setAppArray(appOptions)
                    if(appOptions.length === 1){
                        formik.setFieldValue("appId", {id:appOptions[0].id,label:appOptions[0].label})
                    }
                } else {
                    let appOptionsRoles = []
                    roleID.map((value) => {
                        appOptions.map((item) => {
                            if (item.id === value) {
                                appOptionsRoles.push({
                                    'label': item.label,
                                    'id': item.id
                                })
                            }
                            return item
                        })
                        return value
                    })
                    setAppArray(appOptionsRoles)
                    if(appOptionsRoles.length === 1){
                        formik.setFieldValue("appId", {id:appOptionsRoles[0].id,label:appOptionsRoles[0].label})
                    }
                    
                }
            }
            )
            .catch((err) => {
                
                if(err.response.status === 429){
                    setopenError(true);
                    setErrorMessage('Too Many Requests')
                }else{
                    console.log(err)
                }
            })
    }



    const [customProps, setCustomProps] = React.useState(null)
    const [baseProps, setBaseProps] = React.useState(null)
    const [required, setRequired] = React.useState(null)
    const [customRequired, setCustomRequired] = React.useState(null)

    const getDefaultUserSchema = async () => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}/apps/get-default-user-schema`)
            .then((res) => {
                setBaseProps(res.data.definitions.base.properties)
                setRequired(res.data.definitions.base.required)
                setCustomProps(res.data.definitions.custom.properties)
                setCustomRequired(res.data.definitions.custom.required)
            })
            .catch((err) => {
                if(err.response.status === 429){
                    setopenError(true);
                    setErrorMessage('Too Many Requests')
                }else{
                    console.log(err)
                }
            
        })
    }

    //UNCOMMENT
    React.useEffect(() => {
        getDefaultUserSchema();
        //eslint-disable-next-line
    }, [])

    // const getUserSchemaByApp = async () => {
    //     await axios
    //         .get(`${REACT_APP_API_ENDPOINT}/apps/get-user-schema-by-app/${oktaAppID}`)
    //         .then((res) => {
    //             setCustomProps(res.data.definitions.custom.properties)
    //             setCustomRequired(res.data.definitions.custom.required)
    //         })
    //         .catch((err) => { console.log(err) })
    // }

    // React.useEffect(() => {
    //     if (oktaAppID) {
    //         //UNCOMMENT
    //         getUserSchemaByApp()
    //     }
    //     //eslint-disable-next-line
    // }, [oktaAppID])

    // const [attributes, setAttributes] = React.useState(null)
    let attributes = {};
    const [allRequired, setAllRequired] = React.useState([])

    customProps ? attributes = {
        ...baseProps,
        ...customProps
    }
        : attributes = baseProps

    React.useEffect(() => {
        customRequired ? setAllRequired(required?.concat(customRequired))
            :
            setAllRequired(required)
        //eslint-disable-next-line
    }, [required, customRequired])

    let initValue = {  
    }

    initValue = { 
    ...initValue, 
    appId: {id:routeState?.state?.applicationId?routeState?.state?.applicationId:"",
    label:routeState?.state?.applicationName?routeState?.state?.applicationName:""},
    groupIds: [],
    roles: [],
    sendEmail: "1",
}
    let validityObject = {};
    if (attributes) {
        Object.entries(attributes).map(([key, value]) => {
            if (key === 'login') {

            } else if (value?.type === 'boolean') {
                initValue = {
                    ...initValue,
                    [key]: false
                }
            }
            else if (value?.type === 'array') {
                if (value?.items?.oneOf && value?.items?.oneOf.length > 0) {
                    initValue = {
                        ...initValue,
                        [key]: []
                    }
                }
                else {
                    initValue = {
                        ...initValue,
                        [key]: ""
                    }
                }
            } else {
                initValue = {
                    ...initValue,
                    [key]: ""
                }
            }

            if (key === "login") {

            } else if (key === "email") {
                validityObject[key] = yup.string().required('Email is required').email('Email must be valid').test("Domain validation", "Please Contact Associate team to create pepsico Email ID's ", (value) => {
                    const Domain = value?.split('@')[1]?.toLowerCase()
                    const PepsiDomain = "pepsico.com"
                    // console.log({Domain},{PepsiDomain})
                    if(Domain === PepsiDomain){
                        return false
                    }else{
                        return true
                    }
                })
            } else if (createUser && value?.type === 'array' && value?.items?.enum && value?.items?.oneOf && value?.items?.enum.length > 0 && value?.items?.oneOf.length > 0) {
                validityObject[key] = yup.array().of(yup.string().required("This field is Required"))
            } else if (createUser && value?.type === 'array' && value?.itemType === 'integer') {
                validityObject[key] = yup.string().test("required", "please enter comma seperated integer values", (value) => {
                    if (createUser && value) {
                        let regex = /^[0-9]+(,?[0-9]+)*?$/g
                        let Data = value.match(regex);
                        if (Data) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                })

            } else if (value?.type === "integer") {
                validityObject[key] = yup.string().test("required", "please Enter numbers only", (values) => {
                    if (createUser && values) {
                        let regex = /^[0-9]*$/
                        let Data = values.match(regex);
                        if (Data) {
                            return true;
                        } else {
                            return false;
                        }

                    }
                })
            }
            else if (value?.type === 'array' && value?.itemType === 'string') {
                validityObject[key] = yup.string().test("required", "please enter comma seperated string values", (value) => {
                    if (createUser && value ) {
                        let regex = /^[a-zA-Z]+(,?[a-zA-Z]+)*?$/g
                        let Data = value.match(regex);
                        if (Data) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                })

            }
            else {
                validityObject[key] =
                    yup.string()
                        .test("required", getErrorMsg(key, 1, value?.title), (val) => {
                            let requiredAtt = allRequired.includes(key)
                            if (requiredAtt && createUser) {
                                if (val) {

                                } else {
                                    return false;
                                }
                            }
                            return true
                        })
                        .test("minLength", getErrorMsg(value?.minLength, 2, value?.title), (val) => {
                            if (createUser && val && value?.minLength && value?.minLength > val.length) {
                                return false;
                            }
                            return true;
                        })
                        .test("maxLength", getErrorMsg(value?.maxLength, 3, value?.title), (val) => {
                            if (createUser && val && value?.maxLength && value.maxLength < val.length) {
                                return false;
                            }
                            return true;
                        })
            }

            return value
        })

        if (passwordRequired) {
            validityObject["password"] = yup
                .string()
                .required('Password is Required')
                .min(
                    passwordComplexity.minLength,
                    //eslint-disable-next-line
                    ('At least' + ' ' + passwordComplexity.minLength + ' ' + 'Characters Required')
                )
                //eslint-disable-next-line
                .test("minNumber", ('At least' + ' ' + passwordComplexity.minNumber + ' ' + 'number Required'), (value) => {
                    if (value && passwordComplexity.minNumber > 0) {
                        const regex = /\d/g;
                        let number = value.match(regex);
                        if (number && number.length >= passwordComplexity.minNumber) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                })
                .test("minLowercase", 'One lowercase is required', (value) => {
                    if (value && passwordComplexity.minLowerCase > 0) {
                        const regex = /[a-z]/g;
                        let text = value.match(regex);
                        if (text && text.length >= passwordComplexity.minLowerCase) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                })
                .test("minUppercase", 'One uppercase is required', (value) => {
                    if (value && passwordComplexity.minUpperCase > 0) {
                        let text = value.match(/[A-Z]/g);
                        if (text && text.length >= passwordComplexity.minUpperCase) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                })
                .test("minSymbol", 'One Symbol  is required', (value) => {
                    if (value && passwordComplexity.minSymbol > 0) {
                        let text = value.match(/[^A-Z0-9a-z]/g);
                        if (text && text.length >= passwordComplexity.minSymbol) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }).test(
                    'no_Username',
                    "No Part Of UserName",
                    (value) => {
                      let Email = formik?.values?.email;
                      if (Email?.includes('@')) {
                        Email = Email?.split('@')[0];
                      }
                      if (value && passwordComplexity?.excludeUsername) {
                        if (Email !== '') {
                          if (
                            value?.toLowerCase()?.includes(Email.toLowerCase())
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        } else {
                          return true;
                        }
                      }
                      return true;
                    }
                  )
                  .test(
                    'no_Firstname',
                    "No Part Of FirstName",
                    (value) => {
                      let Firstname = formik?.values?.firstName;
                      if (
                        value &&
                        passwordComplexity?.excludeAttributes?.length > 0 &&
                        passwordComplexity.excludeAttributes.indexOf('firstName') !== -1
                      ) {
                        if (formik?.values?.firstName !== '') {
                          if (value.toLowerCase().includes(Firstname.toLowerCase())) {
                            return false;
                          } else {
                            return true;
                          }
                        } else {
                          return true;
                        }
                      }
                      return true;
                    }
                  )
                  .test(
                    'no_Lastname',
                    "No Part Of LastName",
                    (value) => {
                      if (
                        value &&
                        passwordComplexity?.excludeAttributes?.length > 0 &&
                        passwordComplexity.excludeAttributes?.indexOf('lastName') !== -1
                      ) {
                        if (formik?.values?.lastName !== '') {
                          if (
                            value
                              .toLowerCase()
                              .includes(formik?.values?.lastName.toLowerCase())
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        } else {
                          return true;
                        }
                      }
                      return true;
                    }
                  );;
        }

        if(AssignUser){
            delete validityObject?.sendEmail
            validityObject["sendEmail"] = yup
            .string()
        }else{
            delete validityObject?.sendEmail
            validityObject["sendEmail"] = yup
            .string()
            .required('This field is required')
        }
        
        validityObject["appId"] = yup.object().nullable().test("require","Application Name is required", value=>{
            if(value &&  value?.id && value?.label){
                return true;
            }
            return false;
        })

        validityObject["groupIds"] = yup.array().test("required", "Group ID is required", value => {
            if (value.length > 0) {
                return true;
            }
            return false;
        })
        validityObject['roles'] =yup.array().test("required",  "Roles is required", value => {
            if (value.length > 0) {
                return true;
            }
            return false;
        })
    }

    // let validationObject = {
    //     appId: yup.string()
    //         .required('Application ID is required'),
    //     groupIds: yup.array().test("required", "Group ID is required", value => {
    //         if (value.length > 0) {
    //             return true;
    //         }
    //         return false;
    //     }),
    // }

    // let validationObj = {
    //     ...validationObject,
    //     ...validityObject,
    // }


    const validationSchema = yup.object(validityObject)

    function getErrorMsg(key, type, title) {
        if (type === 1) {
            //eslint-disable-next-line
            return (title + ' ' + 'is required')
        }
        else if (type === 2) {
            //eslint-disable-next-line
            return ('Atleast' + ' ' + key + ' ' + 'characters are required')
        }
        else if (type === 3) {
            //eslint-disable-next-line
            return ('Maximum' + ' ' + key + ' ' + 'characters are allowed')
        }
        else if (type === 4) {
            //eslint-disable-next-line
            return ('One' + ' ' + key + ' ' + 'is Required')
        }
        else if (type === 5) {
            //eslint-disable-next-line
            return ('No part of' + ' ' + key )
        }
        else if (type === 6) {
            //eslint-disable-next-line
            return ('No part of' + ' ' + key)
        }
        else if (type === 4) {
            //eslint-disable-next-line
            return ('No part of' + ' ' + key )
        }
    }

    const formik = useFormik({
        initialValues: initValue,
        validationSchema: validationSchema,
        onSubmit: (values, props) => {
            setTimeout( () => {
                props.resetForm()
            }, 1000)
            console.log(values.groupIds)
            // values.groupIds = groupIDSelected;
            values.appId = oktaAppID;
            values.roles = SettingRoles ?  values.roles.filter( item => item !== 'None') : values.roles
            if (values.sendEmail === '1') {
                values.sendEmail = true;
            } else if (values.sendEmail === '0') {
                values.sendEmail = false;
            }

            let object = {
                ...values
            }

            delete object.appId;
            delete object.roles;

            if (attributes) {
                Object.entries(attributes).map(([key, value]) => {
                    if (value?.type === 'array' && !value?.items?.enum && !value?.items?.oneOf) {
                        if (object[value]) {
                            object[key] = object[key]?.split(',')
                        }
                    }
                    return value
                })
            }

            let allAttributesObject = { groupIds: values.groupIds, appId: oktaAppID }
            Object.entries(attributes).map(([keyTemp, valueTemp]) => {

                if (object[keyTemp]) {
                    allAttributesObject = { ...allAttributesObject, [keyTemp]: object[keyTemp] }
                } else {
                    if (valueTemp?.type === 'array') {
                        if (valueTemp?.items?.oneOf && valueTemp?.items?.oneOf.length > 0) {
                            allAttributesObject = { ...allAttributesObject, [keyTemp]: [] }
                        } else {
                            allAttributesObject = { ...allAttributesObject, [keyTemp]: '' }
                        }
                        allAttributesObject = { ...allAttributesObject, [keyTemp]: [] }
                    } else if (valueTemp?.type === 'boolean') {
                        allAttributesObject = { ...allAttributesObject, [keyTemp]: false }
                    } else {
                        allAttributesObject = { ...allAttributesObject, [keyTemp]: '' }
                        // if (valueTemp?.oneOf && valueTemp?.oneOf.length > 0) {
                        //     allAttributesObject = { ...allAttributesObject, [keyTemp]: [] }
                        // } else {
                        //     allAttributesObject = { ...allAttributesObject, [keyTemp]: '' }
                        // }
                    }
                }
                return valueTemp
            })

            // console.log('allAttributesObject- ', allAttributesObject)
            // console.log('object- ', object)

            createNewUser(object)
        }
    })


    React.useEffect(() => {
        if (formik.values.sendEmail && formik.values.sendEmail === '1') {
            setPasswordRequired(false)
            delete validityObject['password']
        } else if (formik.values.sendEmail && formik.values.sendEmail === '0') {
            setPasswordRequired(true)
        }
        //eslint-disable-next-line
    }, [formik.values.sendEmail])

    

    const passwordPolicy = async () => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}/policies/get-password-policies`)
            .then((res) => {
                formik?.values?.groupIds?.map((i) => {
                    const policyObject = res.data.filter((item) => {
                        const groups = item.conditions.people.groups.include;
                        if (
                            groups.filter((groupId) => groupId === i).length > 0
                        ) {
                            return true;
                        }
                        return false;

                    });
                    if (policyObject.length > 0) {
                        setPaswordComplexity(policyObject[0].settings.password.complexity);
                    } else {
                        setPaswordComplexity(res.data[res.data.length - 1].settings.password.complexity);
                    }

                    return i
                })

            })
            .catch((err) => {
                if(err.response.status === 429){
                    setopenError(true);
                    setErrorMessage('Too Many Requests')
                }else{
                    console.log(err)
                }
            })
    }

    // React.useEffect(() => {
    //     // setGroupIDSelected(allGroupsArray?.find((item) => item.label === formik.values.groupIds));
    //     let tempArray = []
    //     let tempArrayID = []
    //     formik.values.groupIds.map(
    //         (i) => {
    //             tempArray.push(allGroupsArray?.find((item) => item.label === i))
    //             return i
    //         }

    //     )
    //     tempArray.map((i) => {
    //         tempArrayID.push(i?.id)
    //         return i
    //     })
    //     setGroupIDSelected(tempArrayID)
    //     //eslint-disable-next-line
    // }, [formik.values.groupIds])

    React.useEffect(() => {
        if(formik?.values?.groupIds.length > 0 && formik?.values?.sendEmail === '0'){
            passwordPolicy()
        }
        //eslint-disable-next-line
    }, [formik?.values?.groupIds,formik?.values?.sendEmail])

    const getGroupsByApp = async () => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}/apps/get-groups-by-app/${oktaAppID}?limit=1000`)
            .then((res) => {
                
                let optionsGroup = []
                res.data?.data?.body.map((item) => {
                    optionsGroup.push({
                        id: item?.id,
                        label: item?._embedded?.group?.profile?.name
                    })
                    return item
                })
                // setGroupArray(optionsGroup)
                setGroupLabelsArray(optionsGroup)
                if(optionsGroup?.length === 1){
                    formik.setFieldValue("groupIds", [optionsGroup[0].id])
                }
            })
            .catch((err) => {
                if(err.response.status === 429){
                    setopenError(true);
                    setErrorMessage('Too Many Requests')
                }else{
                    console.log(err)
                }
            })
    }

    const RefreshGroupByApps = async () => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}/apps/refresh/${oktaAppID}?limit=1000`)
            .then((res) => {
                
                let optionsGroup = []
                res.data?.data?.body.map((item) => {
                    optionsGroup.push({
                        id: item?.id,
                        label: item?._embedded?.group?.profile?.name
                    })
                    return item
                })
                // setGroupArray(optionsGroup)
                setGroupLabelsArray(optionsGroup)
                if(optionsGroup?.length === 1){
                    formik.setFieldValue("groupIds", [optionsGroup[0].id])
                }
            })
            .catch((err) => {
                if(err.response.status === 429){
                    setopenError(true);
                    setErrorMessage('Too Many Requests')
                }else{
                    console.log(err)
                }
            })
    }

    React.useEffect(() => {

        if (oktaAppID && oktaAppID !== null) {
            getGroupsByApp();
        }
        //eslint-disable-next-line
    }, [oktaAppID])

    React.useEffect(() => {
        const appId = appArray?.find(i => i.id === formik?.values?.appId?.id)
        formik.values.groupIds = []
        // setGroupIDSelected([])
        if (appId) {
            setOktaAppID(appId.id)
        }
        //eslint-disable-next-line
    }, [formik?.values?.appId?.id])

    // const getGroups = async () => {
    //     await axios
    //         .get(`${REACT_APP_API_ENDPOINT}/groups/get-groups`)
    //         .then((res) => {
    //             let groupOptions = []
    //             res.data?.data.body.map((item, key) => {
    //                 groupOptions.push({
    //                     'label': item.profile.name,
    //                     'id': item.id
    //                 })
    //                 return item
    //             })

    //             setAllGroupsArray(groupOptions)
    //         }
    //         )
    //         .catch((err) => console.log(err))
    // }

    React.useEffect(() => {
       
        if (oktaAppID ) {
            getGroupsByApp();
        }
            // getGroups();
        //eslint-disable-next-line
    }, [])

    

    // React.useEffect(() => {
    //     let optionsGroupLabelsArray = []
    //     groupArray?.map((i) => {
    //         allGroupsArray?.map((j) => {
    //             if (i === j.id) {
    //                 optionsGroupLabelsArray.push({
    //                     'label': j.label,
    //                     'id': j.id
    //                 })
    //             }
    //             return j
    //         })
    //         return i
    //     })
    //     setGroupLabelsArray(optionsGroupLabelsArray)
    //     if(optionsGroupLabelsArray?.length === 1){
    //         formik.setFieldValue("groupIds", [optionsGroupLabelsArray[0].label])
    //     }
    //     //eslint-disable-next-line
    // }, [groupArray])


    const checkUserExist = async()=>{
        await axios
        .get(`${REACT_APP_API_ENDPOINT}/users/get-user-detail-by-email/${formik.values["email"]}`)
        .then((res) => {
            if (res.data.responseStatus === 'SUCCESS') {
                setUserId(res.data.data.id);
                setUserExist(true);
                setOpen(true);
                if(res.data.data.profile?.roles){
                    setUpdatedRoles(res.data.data.profile?.roles)
                }
                formik.setFieldValue("sendEmail", "1")
                getApps("")
            }else{
                getApps("")
                setUserExist(false);
                setCreateUser(true)
            }
        })
        .catch((err) => {
            if(err.response.status === 429){
                setopenError(true);
                setErrorMessage('Too Many Requests')
            }else{
                console.log(err)
            }
        })
    }


    const createNewUser = async (obj) => {
      //  delete obj.roles;
        await axios
            .post(`${REACT_APP_API_ENDPOINT}/users/create-user/${oktaAppID}`, obj)
            .then((res) => {
                setOpenSuccess(true)
                setSuccessMessage(res.data.messgae)
                if (res.data.responseStatus === 'SUCCESS') {
                    history(`/user/list-users`)
                }
            }
            )
            .catch((err) => {
                setopenError(true);
                setErrorMessage(err?.response?.data?.errorCauses[0]?.errorSummary)
            })
    }

    //***************** || Alert Handle Close  || ************************* */ 

    const handleCloseAlertError = () => {
        setErrorMessage("");
        setopenError(false);
    };

    const handleCloseAlertSuccess = () => {
        setSuccessMessage("");
        setOpenSuccess(false);
    };


    const FormikSubmitFunction = async() => {
        // if(formik.values.appId !== ''){
        //     Object.entries(formik.errors).map( item => {
        //         formik.setFieldTouched(item[0], true, true)
        //         return item
        //     })
        // }else{
        //     formik.setFieldTouched('appId', true, true)
        // }
        const errors = await formik.validateForm()
        if(Object.keys(formik.errors).length === 0 && formik?.values?.appId?.id !== ''){
            formik.handleSubmit()
        }else {
            formik.setTouched(setNestedObjectValues(errors, true));
        }
    }

    const getAppData = async () => {
        axios.get(`${REACT_APP_API_ENDPOINT}/apps/get-app/${formik?.values?.appId?.id}`)
        .then( res => {
            if(res.data.settings.notes){
                setSettingRoles(res.data.settings?.notes)
                formik.setFieldValue("roles", [])
            }else{
                setSettingRoles("")  
                formik.setFieldValue("roles", ["None"])
            }
        }).catch( err => {
            if(err.response.status === 429){
                setopenError(true);
                setErrorMessage('Too Many Requests')
            }else{
                console.log(err)
            }
        })
     }

    React.useEffect(() => {
        if( formik?.values?.appId?.id){
            getAppData()
        }
        //eslint-disable-next-line
    }, [formik?.values?.appId?.id])

    

    /***DIALOG START */
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
    };
    const handleAgree = async ()=>{
        setUserExist(true) 
        setAssignUser(true)
        setOpen(false);     

        // await groupLabelsArray.map((item=>{
        //      axios
        //     .post(`${REACT_APP_API_ENDPOINT}/groups/assign-user-to-group/${item.id}/${userId}`)
        //     .then((res) => {
        //         setOpenSuccess(true)
        //         setSuccessMessage(res.data.messgae)
        //         }
        //     )
        //     .catch((err) => {
        //         setopenError(true);
        //         setErrorMessage(err?.response?.data?.errorCauses[0]?.errorSummary)
        //     })
        // return item
        // }))
        
    }

    const AssignOnAgree = async () => {
        // const NewRoles = SettingRoles ? formik?.values?.roles.filter( item => item !== 'None') : formik?.values?.roles
        // const UpdatingFinalRoles = UpdatedRoles.concat(NewRoles)
        // let uniqueRoles = [...new Set(UpdatingFinalRoles)];
 
        await groupLabelsArray.map((item=>{
            axios
           .post(`${REACT_APP_API_ENDPOINT}/groups/assign-user-to-group/${item.id}/${userId}`)
           .then((res) => {
                    setOpenSuccess(true)
                    setSuccessMessage("User Assigned And Updated Successfully")
                    if (res.data.responseStatus === 'SUCCESS') {
                        history(`/user/list-users`)
                    }
                // axios.post(`${REACT_APP_API_ENDPOINT}/users/update-user/${userId}`,
                //  {
                //      roles : uniqueRoles
                //  }
                // )
                // .then((res) => {
                //     setOpenSuccess(true)
                //     setSuccessMessage("User Assigned And Updated Successfully")
                // }
                // )
                // .catch((err) => {
                //     setopenError(true);
                //     setErrorMessage(err?.response?.data?.errorCauses[0]?.errorSummary)
                // })
            }
           )
           .catch((err) => {
               setopenError(true);
               setErrorMessage(err?.response?.data?.errorCauses[0]?.errorSummary)
           })
       return item
       }))
    }

    const ResetForm = () => {
         formik.resetForm()
         setUserExist(true)
         setAssignUser(false)
         setCreateUser(false)
    }


    return (
        <>
            <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
            <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
            <MainCard title='Create User' id="Main">
                <Box
                    sx={{
                        '&.MuiTextField-root': { m: 0 },
                        flexGrow: 1
                    }}
                >
                    <Grid container spacing={4}>
                        {
                            baseProps ? 
                            Object.entries(baseProps).map(([key, value]) => {
                                if (key === 'login') {
                                    return <Grid key={key + '_login'}></Grid>
                                } else if( key === 'email' && value?.required){
                                    return(
                                        <React.Fragment key={key + '_parent'}>
                                            <Grid item xs={6} key={key + '_email'}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        id={key}
                                                        name={key}
                                                        label={value?.title}
                                                        disabled ={ (!userExist ? true : false) || (AssignUser ? true : false)}
                                                        value={formik.values[key] || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                    >
                                                    </TextField>
                                                </Grid>
                                        </React.Fragment>
                                    )
                                }
                                return null
                            })
                            : null
                        }
                        {
                            (userExist && AssignUser) || createUser   ? 
                        <>                      
                            <Grid item xs={6}>

                                <Autocomplete 
                                 fullWidth
                                 filterOptions={(x) => x}
                                 options={appArray}
                                 name='appId'
                                 value={formik.values.appId || null}
                                // defaultValue={formik.values.appId ? {id:formik.values["appId"],label:formik.values["appName"]} : null}
                                 onChange={ (e , value) => {
                                    formik.setFieldValue('appId', value);
                                 }}
                                 onBlur={(e) => {
                                    formik.setFieldTouched('appId')
                                    formik.setFieldTouched('appName')
                                 }}
                                 isOptionEqualToValue={(option, value) => option.id === value.id}
                                 getOptionLabel={option=>{
                                    return option.label
                                 }}
                                 renderInput= {
                                    (params) => (
                                    <TextField
                                        {...params}
                                        label='Application Name*'
                                        onChange={(e)=>{
                                            getApps(e.target.value)
                                        }}
                                        error={formik.touched['appId'] &&  Boolean(formik.errors['appId'])}
                                        helperText={formik.touched['appId'] &&formik.errors['appId']}
                                    />
                                    )
                                 }
                                />
                            </Grid>
                            <Grid item xs={6} display="flex" flexDirection="column" justifyContent="right" alignItems="right">
                                <TextField
                                    select
                                    required
                                    fullWidth
                                    name='groupIds'
                                    label='Group ID'
                                    SelectProps={{ multiple: true }}
                                    value={formik.values['groupIds'] || []}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched['groupIds'] && Boolean(formik.errors['groupIds'])}
                                    helperText={formik.touched['groupIds'] && formik.errors['groupIds']}
                                >
                                    <MenuItem value='' style={{ display: 'none' }}>Please select</MenuItem>
                                    {groupLabelsArray?.map((option) => (
                                        <MenuItem key={option.id + '_appID'} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}

                                </TextField>
                                {
                                formik.values.appId?.id &&
                                <Typography variant="caption" fontSize="1rem" component="div" color="text.primary" onClick={RefreshGroupByApps}  style={{cursor:'pointer',margin:'0.5%', textDecoration:'underline', color:'#0c94e8', textAlign: 'right'}}>
                                    Refresh
                                </Typography>
                            }
                            </Grid>
                            {/* {
                              SettingRoles &&  SettingRoles.admin.length > 0 ? */}
                            <Grid item xs={6}>
                               <TextField
                               select
                               required
                               fullWidth
                                    name='roles'
                                    label='Roles'
                                    SelectProps={{ multiple: true}}
                                    value={formik.values['roles']}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched['roles'] && Boolean(formik.errors['roles'])}
                                    helperText={formik.touched['roles'] && formik.errors['roles']}
                               >
                                <MenuItem value='' style={{display: 'none'}}>Please Select</MenuItem>
                                {
                                    SettingRoles &&
                                    SettingRoles?.admin?.split(',').map((option) => (
                                        <MenuItem key={option + '_Role'} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))  
                                }
                                <MenuItem value="None">None</MenuItem>
                               </TextField> 
                            </Grid> 
                            
                        </> 
                        : null 
                        }

                       
                            
                        
                        {
                            baseProps && createUser ?
                                Object.entries(baseProps).map(([key, value]) => {
                                    if (key === 'login') {
                                        return <Grid key={key + '_login'}></Grid>
                                    } else if (key === 'email' && value?.required) {
                                        return (
                                            <React.Fragment key={key + '_parent'}>
                                                {/* <Grid item xs={6} key={key + '_email'}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        id={key}
                                                        name={key}
                                                        label={value?.title}
                                                        value={formik.values[key] || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                    >
                                                    </TextField>
                                                </Grid> */}
                                                {
                                                    !userExist?
                                                    <>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                select
                                                                required={AssignUser ? false : true}
                                                                name='sendEmail'
                                                                id="sendEmail"
                                                                fullWidth
                                                                label="Do you want to send activation email?"
                                                                value={formik.values.sendEmail || ''}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                error={formik.touched.sendEmail && Boolean(formik.errors.sendEmail)}
                                                                helperText={formik.touched.sendEmail && formik.errors.sendEmail}
                                                                
                                                            >
                                                                <MenuItem value="1">Yes</MenuItem>
                                                                <MenuItem value="0">No</MenuItem>
                                                            </TextField>

                                                        </Grid>
                                                    </>:
                                                    null
                                                }
                                               
                                                {formik.values.sendEmail === '0' ? <>
                                                    <Grid item xs={6} key={key + '_password'}>
                                                        <TextField
                                                            fullWidth
                                                            required={passwordRequired}
                                                            type='password'
                                                            id='password'
                                                            name='password'
                                                            label='Password'
                                                            value={formik.values.password || ''}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                                            helperText={formik.touched.password && formik.errors.password}
                                                        >
                                                        </TextField>
                                                    </Grid>
                                                </> : <></>}
                                            </React.Fragment>

                                        )

                                    } else if (value?.type === 'boolean' && value?.required ) {
                                        let requiredAtt = allRequired?.includes(key)
                                        return (
                                            <Grid item xs={6} key={key + '_boolean'}>
                                                <input type='checkbox'
                                                    required={requiredAtt}
                                                    name={key}
                                                    id={key}
                                                    value={formik.values[key] || ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                // error={formik.touched[key] && Boolean(formik.errors[key])}
                                                // helperText={formik.touched[key] && formik.errors[key]}
                                                />
                                                {value?.title}
                                                {formik.touched[key] &&
                                                    formik.errors[key] ? (
                                                    <div className="error-msg">
                                                        {formik.errors[key]}
                                                    </div>
                                                ) : null}
                                            </Grid>

                                        )
                                    }
                                    else if (value?.type === 'array' && value?.required) {
                                        let requiredAtt = allRequired?.includes(key)
                                        if (value?.items?.oneOf && value?.items?.oneOf.length > 0 && value?.items?.enum && value?.items?.enum.length > 0 && value?.required) {
                                            return (
                                                <Grid item xs={6} key={key + '_arrayList'}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        name={key}
                                                        label={value?.title}
                                                        SelectProps={{ multiple: true }}
                                                        value={formik.values[key] || []}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                        required={requiredAtt}
                                                    >
                                                        {value?.items?.oneOf.map((key, name) => {
                                                            return (
                                                                <MenuItem
                                                                    key={key.const + "_multiselect"}
                                                                    value={name.const}
                                                                >
                                                                    {name.title}
                                                                </MenuItem>
                                                            )
                                                        }
                                                        )}
                                                    </TextField>

                                                </Grid>
                                            )
                                        }
                                        else {
                                            return (
                                                value?.required ?
                                                <Grid item xs={6} key={key + "_array"}>
                                                    <TextField
                                                        fullWidth
                                                        id={key}
                                                        name={key}
                                                        label={value?.title}
                                                        value={formik.values[key] || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                        required={requiredAtt}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                : null
                                            )
                                        }
                                    }
                                    else {
                                        let requiredAtt = allRequired?.includes(key)
                                        if (value?.enum && value?.enum.length > 0 && value?.oneOf && value?.oneOf.length > 0 && value?.required) {
                                            return (
                                                <Grid item xs={6} key={key + "_select"}>

                                                    <TextField
                                                        select
                                                        fullWidth
                                                        name={key}
                                                        value={formik.values[key] || []}
                                                        onChange={formik.handleChange}
                                                        label={value?.title}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                        required={requiredAtt}
                                                    >
                                                        {
                                                            value?.oneOf.map((item) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={item.const}
                                                                        value={item.title}
                                                                    >
                                                                        {item.title}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                            )
                                        }
                                        else {
                                            return (
                                                value?.required ? 
                                                <Grid item xs={6} key={key + "_other"}>
                                                    <TextField
                                                        fullWidth
                                                        id={key}
                                                        name={key}
                                                        label={value?.title}
                                                        value={formik.values[key] || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                        required={requiredAtt}
                                                    >
                                                    </TextField>
                                                </Grid> : null
                                            )
                                        }
                                    }

                                })
                                : null
                        }
                        {
                            customProps ?
                                Object.entries(customProps).map(([key, value]) => {
                                    if (value?.type === 'boolean' && value?.required) {
                                        let requiredAtt = allRequired?.includes(key)
                                        return (
                                            <Grid item xs={6} key={key + '_boolean'} style={{ paddingLeft: '75px', transform: 'scale(1.2)', paddingTop: '44px' }}>
                                                <input type='checkbox'
                                                    name={key}
                                                    id={key}
                                                    value={formik.values[key] || ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    required={requiredAtt}
                                                // error={formik.touched[key] && Boolean(formik.errors[key])}
                                                // helperText={formik.touched[key] && formik.errors[key]}
                                                />
                                                {value?.title}
                                                {formik.touched[key] &&
                                                    formik.errors[key] ? (
                                                    <div className="error-msg">
                                                        {formik.errors[key]}
                                                    </div>
                                                ) : null}
                                            </Grid>

                                        )
                                    }
                                    else if (value?.type === 'array' && value?.required) {
                                        let requiredAtt = allRequired?.includes(key)
                                        if (value?.items?.oneOf && value?.items?.oneOf.length > 0 && value?.items?.enum && value?.items?.enum.length > 0 && value?.required) {
                                            return (
                                                <Grid item xs={6} key={key + '_arrayList'}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        name={key}
                                                        label={value?.title}
                                                        SelectProps={{ multiple: true }}
                                                        value={formik.values[key] || []}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                        required={requiredAtt}
                                                    >
                                                        {value?.items?.oneOf.map((name) => {
                                                            return (
                                                                <MenuItem
                                                                    key={name.const + "_multiselect"}
                                                                    value={name.const}
                                                                >
                                                                    {name.title}
                                                                </MenuItem>
                                                            )
                                                        }
                                                        )}
                                                    </TextField>

                                                </Grid>
                                            )
                                        }
                                        else {
                                            return (
                                                value?.required ? 
                                                <Grid item xs={6} key={key + "_array"}>
                                                    <TextField
                                                        fullWidth
                                                        id={key}
                                                        name={key}
                                                        label={value?.title}
                                                        value={formik.values[key] || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                        required={requiredAtt}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                : null
                                            )
                                        }
                                    }
                                    else {
                                        let requiredAtt = allRequired?.includes(key)
                                        if (value?.enum && value?.enum.length > 0 && value?.oneOf && value?.oneOf.length > 0 && value?.required) {
                                            return (
                                                <Grid item xs={6} key={key + "_select"}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        name={key}
                                                        value={formik.values[key] || []}
                                                        onChange={formik.handleChange}
                                                        label={value?.title}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                        required={requiredAtt}
                                                    >
                                                        {
                                                            value?.oneOf.map((item) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={item.const}
                                                                        value={item.title}
                                                                    >
                                                                        {item.title}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                            )
                                        }
                                        else {
                                            let requiredAtt = allRequired?.includes(key)
                                            return (
                                                value?.required ? 
                                                <Grid item xs={6} key={key + "_other"}>
                                                    <TextField
                                                        fullWidth
                                                        id={key}
                                                        name={key}
                                                        label={value?.title}
                                                        value={formik.values[key] || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                        required={requiredAtt}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                : null
                                            )
                                        }
                                    }
                                })
                                : null
                        }
                    </Grid>
                    {
                        userExist?
                        <>
                        <Grid container spacing={4}>
                            <Grid item xs={6} style={{ margin: '10px 0px' }}>
                                <Stack spacing={1} direction="row">
                                    <Button color="primary" variant="contained" type="button" onClick={AssignUser ? AssignOnAgree : checkUserExist} disabled={ (AssignUser ? !(formik.isValid && formik.dirty) : false) || (formik?.errors?.email ? true : false) } >
                                      { AssignUser ? "Assign User" : "Check Email"}
                                    </Button>
                                    {
                                        AssignUser ?
                                        <Button color="primary" variant="outlined" type="button" onClick={ResetForm} >
                                            clear
                                        </Button>
                                        : null
                                    }
                                </Stack>
                            </Grid>
                        </Grid> 
                        </>
                        :
                        null
                    }
                    
                    {
                        !userExist ?
                        <>
                            <Accordion className={classes.root}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <Typography style={{fontSize:'0.9rem', fontWeight: '900', fontFamily: 'Roboto', color:'black'}}>Show More</Typography>
                                </AccordionSummary>
                                <AccordionDetails > 
                                    <Grid container spacing={4}>
                                        {
                                            baseProps ?
                                                Object.entries(baseProps).map(([key, value]) => {
                                                    if (key === 'login') {
                                                        return <Grid key={key + '_login'}></Grid>
                                                    } else if (key === 'email' && !value?.required) {
                                                        return (
                                                            <React.Fragment key={key + '_parent'}>
                                                                <Grid item xs={6} key={key + '_email'}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required
                                                                        id={key}
                                                                        name={key}
                                                                        label={value?.title}
                                                                        value={formik.values[key] || ''}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                                    >
                                                                    </TextField>
                                                                </Grid>
                                                            </React.Fragment>

                                                        )

                                                    } else if (value?.type === 'boolean' && !value?.required ) {
                                                        let requiredAtt = allRequired?.includes(key)
                                                        return (
                                                            <Grid item xs={6} key={key + '_boolean'}>
                                                                <input type='checkbox'
                                                                    required={requiredAtt}
                                                                    name={key}
                                                                    id={key}
                                                                    value={formik.values[key] || ''}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                // error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                // helperText={formik.touched[key] && formik.errors[key]}
                                                                />
                                                                {value?.title}
                                                                {formik.touched[key] &&
                                                                    formik.errors[key] ? (
                                                                    <div className="error-msg">
                                                                        {formik.errors[key]}
                                                                    </div>
                                                                ) : null}
                                                            </Grid>

                                                        )
                                                    }
                                                    else if (value?.type === 'array' && !value?.required) {
                                                        let requiredAtt = allRequired?.includes(key)
                                                        if (value?.items?.oneOf && value?.items?.oneOf.length > 0 && value?.items?.enum && value?.items?.enum.length > 0 && !value?.required) {
                                                            return (
                                                                <Grid item xs={6} key={key + '_arrayList'}>
                                                                    <TextField
                                                                        select
                                                                        fullWidth
                                                                        name={key}
                                                                        label={value?.title}
                                                                        SelectProps={{ multiple: true }}
                                                                        value={formik.values[key] || []}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                                        required={requiredAtt}
                                                                    >
                                                                        {value?.items?.oneOf.map((key, name) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={key.const + "_multiselect"}
                                                                                    value={name.const}
                                                                                >
                                                                                    {name.title}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </TextField>

                                                                </Grid>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                !value?.required ?
                                                                <Grid item xs={6} key={key + "_array"}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={key}
                                                                        name={key}
                                                                        label={value?.title}
                                                                        value={formik.values[key] || ''}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                                        required={requiredAtt}
                                                                    >
                                                                    </TextField>
                                                                </Grid>
                                                                : null
                                                            )
                                                        }
                                                    }
                                                    else {
                                                        let requiredAtt = allRequired?.includes(key)
                                                        if (value?.enum && value?.enum.length > 0 && value?.oneOf && value?.oneOf.length > 0 && !value?.required) {
                                                            return (
                                                                <Grid item xs={6} key={key + "_select"}>

                                                                    <TextField
                                                                        select
                                                                        fullWidth
                                                                        name={key}
                                                                        value={formik.values[key] || []}
                                                                        onChange={formik.handleChange}
                                                                        label={value?.title}
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                                        required={requiredAtt}
                                                                    >
                                                                        {
                                                                            value?.oneOf.map((item) => {
                                                                                return (
                                                                                    <MenuItem
                                                                                        key={item.const}
                                                                                        value={item.title}
                                                                                    >
                                                                                        {item.title}
                                                                                    </MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TextField>
                                                                </Grid>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                !value?.required ? 
                                                                <Grid item xs={6} key={key + "_other"}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={key}
                                                                        name={key}
                                                                        label={value?.title}
                                                                        value={formik.values[key] || ''}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                                        required={requiredAtt}
                                                                    >
                                                                    </TextField>
                                                                </Grid> : null
                                                            )
                                                        }
                                                    }

                                                })
                                                : null
                                        }
                                        {
                                            customProps ?
                                                Object.entries(customProps).map(([key, value]) => {
                                                    if (value?.type === 'boolean' && !value?.required) {
                                                        let requiredAtt = allRequired?.includes(key)
                                                        return (
                                                            <Grid item xs={6} key={key + '_boolean'} style={{ paddingLeft: '75px', transform: 'scale(1.2)', paddingTop: '44px' }}>
                                                                <input type='checkbox'
                                                                    name={key}
                                                                    id={key}
                                                                    value={formik.values[key] || ''}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    required={requiredAtt}
                                                                // error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                // helperText={formik.touched[key] && formik.errors[key]}
                                                                />
                                                                {value?.title}
                                                                {formik.touched[key] &&
                                                                    formik.errors[key] ? (
                                                                    <div className="error-msg">
                                                                        {formik.errors[key]}
                                                                    </div>
                                                                ) : null}
                                                            </Grid>

                                                        )
                                                    }
                                                    else if (value?.type === 'array' && !value?.required) {
                                                        let requiredAtt = allRequired?.includes(key)
                                                        if (value?.items?.oneOf && value?.items?.oneOf.length > 0 && value?.items?.enum && value?.items?.enum.length > 0 && !value?.required) {
                                                            return (
                                                                <Grid item xs={6} key={key + '_arrayList'}>
                                                                    <TextField
                                                                        select
                                                                        fullWidth
                                                                        name={key}
                                                                        label={value?.title}
                                                                        SelectProps={{ multiple: true }}
                                                                        value={formik.values[key] || []}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                                        required={requiredAtt}
                                                                    >
                                                                        {value?.items?.oneOf.map((name) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={name.const + "_multiselect"}
                                                                                    value={name.const}
                                                                                >
                                                                                    {name.title}
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </TextField>

                                                                </Grid>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                !value?.required ? 
                                                                <Grid item xs={6} key={key + "_array"}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={key}
                                                                        name={key}
                                                                        label={value?.title}
                                                                        value={formik.values[key] || ''}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                                        required={requiredAtt}
                                                                    >
                                                                    </TextField>
                                                                </Grid>
                                                                : null
                                                            )
                                                        }
                                                    }
                                                    else {
                                                        let requiredAtt = allRequired?.includes(key)
                                                        if (value?.enum && value?.enum.length > 0 && value?.oneOf && value?.oneOf.length > 0 && !value?.required) {
                                                            return (
                                                                <Grid item xs={6} key={key + "_select"}>
                                                                    <TextField
                                                                        select
                                                                        fullWidth
                                                                        name={key}
                                                                        value={formik.values[key] || []}
                                                                        onChange={formik.handleChange}
                                                                        label={value?.title}
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                                        required={requiredAtt}
                                                                    >
                                                                        {
                                                                            value?.oneOf.map((item) => {
                                                                                return (
                                                                                    <MenuItem
                                                                                        key={item.const}
                                                                                        value={item.title}
                                                                                    >
                                                                                        {item.title}
                                                                                    </MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TextField>
                                                                </Grid>
                                                            )
                                                        }
                                                        else {
                                                            let requiredAtt = allRequired?.includes(key)
                                                            return (
                                                                !value?.required ? 
                                                                <Grid item xs={6} key={key + "_other"}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={key}
                                                                        name={key}
                                                                        label={value?.title}
                                                                        value={formik.values[key] || ''}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        error={formik.touched[key] && Boolean(formik.errors[key])}
                                                                        helperText={formik.touched[key] && formik.errors[key]}
                                                                        required={requiredAtt}
                                                                    >
                                                                    </TextField>
                                                                </Grid>
                                                                : null
                                                            )
                                                        }
                                                    }
                                                })
                                                : null
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            
                        </>
                        :
                        null
                    }
                    {
                        createUser ? 
                        <Grid container spacing={4}>
                                    <Grid item xs={6} style={{ margin: '30px 0px' }}>
                                        <Stack spacing={1} direction="row">
                                            <Button color="primary" variant="contained" type="submit" onClick={FormikSubmitFunction} disabled={!(formik.isValid && formik.dirty)}>
                                                Create
                                            </Button>
                                            <Button color="primary" variant="outlined" type="button" onClick={ResetForm}>
                                                Clear
                                            </Button>
                                        </Stack>
                                    </Grid>
                        </Grid>
                    : null
                    }
                    <Grid container spacing={2} sx={{marginTop:"2px"}}>
                    <Grid item xs={6}>
                        <Stack spacing={1} direction="row" >
                            {/* <Button type="submit" variant="contained" color="primary">
                            Update
                            </Button> */}
                            <Button type="button" variant="outlined" color="primary" onClick={() => history(-1) }>
                            Back
                            </Button>
                        </Stack>
                </Grid>
                    </Grid>
                </Box>
            </MainCard>


            {/**
             * 
             * DIALOG
             * START
             */}
             <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Assign User to group?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    User is already exist. Do you want to assign user to {groupLabelsArray?.map(item=>item.label+" ")} group?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleAgree} autoFocus>
                    Agree
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateUser;