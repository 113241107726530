import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
import thunk from 'redux-thunk'


// ==============================|| REDUX - MAIN STORE ||============================== //




// function getStorageData(){
//     if(localStorage.getItem('okta-token-storage')){        
//         const data = JSON.parse(localStorage.getItem('okta-token-storage'));
//         const auth = {
//             accessToken:data?.accessToken,
//             idToken:data?.idToken
//         }
//         const authInitState = {
//             authState : auth,
//             isAuthenticated : data?.accessToken ? true : false
//         }
//         return authInitState   
//     }
//     const loginInitialState = {
//         authState:null,
//         isAuthenticated:false
//     }
//     return loginInitialState;
// }

// const init = {
//     authState:null,
//     isAuthenticated:false
//   }
const store = configureStore({
    reducer,
    middleware: [thunk]
})
const persister = 'Free';

export { store, persister };
