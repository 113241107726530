// material-ui
import { LinearProgress } from '@mui/material';






// ==============================|| LOADER ||============================== //
const Loader = () => {
    return(
      <div style={{position:'relative', backgroundColor: 'red'}}>
              <LinearProgress color="primary" />
      </div>
    
    )
}

export default Loader;
