import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import AnimateButton from "ui-component/extended/AnimateButton";
import AuthCardWrapper from "../authentication/AuthCardWrapper";
import { Link } from "react-router-dom";
import Logo from "ui-component/Logo";
import {  useNavigate } from 'react-router';

const AuthWrapper1 = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    minHeight: '100vh'
}));
const Unauthorised = ()=>{
    const navigate = useNavigate();
    return (
        <AuthWrapper1>
        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                    <Grid item sx={{ m: { xs: 2, sm: 4 }, mb: 0 }}>
                        <AuthCardWrapper>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item sx={{ mb: 3 }}>
                                    <Link to="#">
                                        <Logo />
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="subtitle1">Unauthorised Access !</Typography>
                                            <Typography variant="subtitle1">Sign In to Continue</Typography>
                                        </Box>
                                        <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={()=>navigate('/login')}
                                                    >
                                                        Go to Login
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                </Grid>
                            </Grid>
                          
                        </AuthCardWrapper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </AuthWrapper1>
       
    )
}

export default Unauthorised;