
// import group from './group';
// import other from './other';
import app from './app';
// import utilities from './utilities';
import user from './user'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard,group,app,user]
    items: [app,user]
};

export default menuItems;
