
const languageData ={
    'en_US':{
        ViewThisPage: 'View this page in',
        FirstTimeUser:'First Time User',
        EmailAddress:"Email address",
        ReceiveEmails:"Receive e-mails in",
        EmailError:"Email is Required",
        EmailValid:"Email must be valid",
        Submit:'Submit',
        ActivationSuccess: "Activation Mail sent Successfull",
        ActivationFail:"Activation Mail Not Sent"
    },
    "en":{
        ViewThisPage: 'View this page in',
        FirstTimeUser:'First Time User',
        EmailAddress:"Email address",
        ReceiveEmails:"Receive e-mails in",
        EmailError:"Email is Required",
        EmailValid:"Email must be valid",
        Submit:'Submit',
        ActivationSuccess: "Activation Mail sent Successfull",
        ActivationFail:"Activation Mail Not Sent"

    },
    "zh_CN":{
        ViewThisPage: '查看此页面',
        FirstTimeUser:'注册',
        EmailAddress:"电子邮件地址",
        ReceiveEmails:"接收电子邮件",
        EmailError:"电子邮件地址是必填项",
        EmailValid:"电子邮件必须有效",
        Submit:'提交',
        ActivationSuccess: "激活郵件發送成功",
        ActivationFail:"激活郵件未發送"

    },
    "zh":{
        ViewThisPage: '查看此页面',
        FirstTimeUser:'注册',
        EmailAddress:"电子邮件地址",
        ReceiveEmails:"接收电子邮件",
        EmailError:"电子邮件地址是必填项",
        EmailValid:"電子郵件必須有效",
        Submit:'提交',
        ActivationSuccess: "激活邮件发送成功",
        ActivationFail:"激活邮件未发送"

    },
    "zh_TW":{
        ViewThisPage: '查看此頁面',
        FirstTimeUser:'註冊',
        EmailAddress:"電子郵件地址",
        ReceiveEmails:"接收電子郵件",
        EmailError:"電子郵件地址是必填項",
        EmailValid:"電子郵件必須有效",
        Submit:'送出',
        ActivationSuccess: "激活邮件发送成功",
        ActivationFail:"激活邮件未发送"

    },
    "ru_RU":{
        ViewThisPage: 'Посмотреть эту страницу на',
        FirstTimeUser:'Первый вход в систему',
        EmailAddress:"Требуется адрес электронной почты",
        ReceiveEmails:"Получать письма на",
        EmailError:"Требуется адрес электронной почты",
        EmailValid:"Электронная почта должна быть действующей",
        Submit:'Отправить ',
        ActivationSuccess: "Письмо об активации отправлено успешно",
        ActivationFail:"Письмо активации не отправлено"

    },
    "ru":{
        ViewThisPage: 'Посмотреть эту страницу на',
        FirstTimeUser:'Первый вход в систему',
        EmailAddress:"Требуется адрес электронной почты",
        ReceiveEmails:"Получать письма на",
        EmailError:"Требуется адрес электронной почты",
        EmailValid:"Электронная почта должна быть действующей",
        Submit:'Отправить',
        ActivationSuccess: "Письмо об активации отправлено успешно",
        ActivationFail:"Письмо активации не отправлено"
    },
    "es_ES":{
        ViewThisPage: 'Ver esta página en',
        FirstTimeUser:'Inicio de sesión por primera vez',
        EmailAddress:"Dirección de correo electrónico",
        ReceiveEmails:"Recibir correos en",
        EmailError:"Se requiere E-mail",
        EmailValid:"El email debe ser válido",
        Submit:'Enviar',
        ActivationSuccess: "Correo de activación enviado con éxito",
        ActivationFail:"Correo de activación no enviado"

    },
    "es":{
        ViewThisPage: 'Ver esta página en',
        FirstTimeUser:'Inicio de sesión por primera vez',
        EmailAddress:"Dirección de correo electrónico",
        ReceiveEmails:"Recibir correos en",
        EmailError:"Se requiere E-mail",
        EmailValid:"El email debe ser válido",
        Submit:'Enviar',
        ActivationSuccess: "Correo de activación enviado con éxito",
        ActivationFail:"Correo de activación no enviado"

    },
    "pt_BR":{
        ViewThisPage: 'Ver esta pÃ¡gina em',
        FirstTimeUser:'Primeiro Acesso',
        EmailAddress:"EndereÃ§o de e-mail",
        ReceiveEmails:"Receba e-mails em",
        EmailError:"Ã  necessÃ¡rio um endereÃ§o de e-mail",
        EmailValid:"E-mail deve ser válido",
        Submit:'Enviar',
        ActivationSuccess: "E-mail de ativação enviado com sucesso",
        ActivationFail:"E-mail de ativação não enviado"

    },
    "pt":{
        ViewThisPage: 'Ver esta pÃ¡gina em',
        FirstTimeUser:'Primeiro Acesso',
        EmailAddress:"EndereÃ§o de e-mail",
        ReceiveEmails:"Receba e-mails em",
        EmailError:"Ã  necessÃ¡rio um endereÃ§o de e-mail",
        EmailValid:"E-mail deve ser válido",
        Submit:'Enviar',
        ActivationSuccess:'E-mail de ativação enviado com sucesso',
        ActivationFail:"E-mail de ativação não enviado"

    },
    "uk_UA":{
        ViewThisPage: 'Переглянути цю сторінку в',
        FirstTimeUser:'Користувач першого разу',
        EmailAddress:"Адреса електронної пошти",
        ReceiveEmails:"Отримувати електронні листи в",
        EmailError:"Електронна адреса обов'язкова",
        EmailValid:"Електронна адреса має бути дійсною",
        Submit:'Відправити ',
        ActivationSuccess: "Активаційний лист надіслано успішно",
        ActivationFail:"Лист активації не надіслано"
    },
    "uk":{
        ViewThisPage: 'Переглянути цю сторінку в',
        FirstTimeUser:'Користувач першого разу',
        EmailAddress:"Адреса електронної пошти",
        ReceiveEmails:"Отримувати електронні листи в",
        EmailError:"Електронна адреса обов'язкова",
        EmailValid:" Електронна адреса має бути дійсною",
        Submit:'Відправити ',
        ActivationSuccess: "Активаційний лист надіслано успішно",
        ActivationFail:"Лист активації не надіслано"

    }

}

const language = (code) => {
return languageData[code]
};

export default language;