import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'

// ==============================|| REACT DOM RENDER  ||============================== //

const {REACT_APP_CAPTCHA_SITE_KEY} = process.env

ReactDOM.render(
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA_SITE_KEY} >
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </GoogleReCaptchaProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
