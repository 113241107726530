
import {  Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import * as React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import {  VisibilityOutlined } from '@mui/icons-material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router';
import SuccessNotification from "ui-component/SuccessNotification";
import FailureNotification from "ui-component/FailureNotification";
import { Link } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';



const useStyles = makeStyles((theme) => ({
    table: {
        "& .MuiTableRow-root": {
          border: '1px solid #ececec'
        },
        "& .MuiTableCell-body":{
          padding:'5px 10px',
          border: '1px solid #ececec'
        },
        "& .MuiTableCell-head":{
            padding:theme.spacing(1),
            border: '1px solid #ececec'
        }
      },
    card:{
        "& .MuiPaper-root":{
            marginTop:theme.spacing(1)
          },
    },
    TableButton: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'
      },
    blueIcon:{
        color:'#28458e'
    },
    redIcon:{
        color:'red'
    },
    greenIcon:{
        color:'green'
    },
    ActionItems:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    }
}));

const AdminSearchGroup = () => {
    const classes = useStyles();
    const [filterList, setFilterList] =  React.useState([]);
    const [searchBy, setSearchBy] = React.useState("");
    const [after,setAfter] = React.useState();
    const [show,setShow] = React.useState(true);
    const { state } = useLocation();
    const confirm = useConfirm();
    const [openError, setopenError] = React.useState(state?.isSuccess ? false:null);
    const [ErrorMessage, setErrorMessage] = React.useState(state?.msg?state?.msg:"");
    const [openSuccess, setOpenSuccess] = React.useState(state?.isSuccess ? true:null);
    const [SuccessMessage, setSuccessMessage] = React.useState(state?.msg?state?.msg:"");
    const handleCloseAlertError = () => {
        setErrorMessage("");
        setopenError(false);
    };

    const handleCloseAlertSuccess = () => {
        setSuccessMessage("");
        setOpenSuccess(false);
    };

    const { REACT_APP_API_ENDPOINT } = process.env


    const handleSearchByChange = (event)=>{
        setSearchBy(event.target.value);
    }

    React.useEffect(()=>{
        let filterArray =  filterList.filter(item=>item.profile.name.toLowerCase().startsWith(searchBy.toLowerCase()));
        setFilterList(filterArray);
        //eslint-disable-next-line
    },[searchBy]);

    React.useEffect(()=>{
        getAllGroups();
        //eslint-disable-next-line
    },[]);

    const getAllGroups =  async ()=>{
        await axios.get(`${REACT_APP_API_ENDPOINT}/groups/get-groups?limit=10`).then(res=>{
            setFilterList(res.data.data.body);
            handleNextLink(res.data.data.nextLink);
        },error=>{
            console.log(error);
        })
    }

    const handleNextLink = (nextLink)=>{
        if(nextLink.length===1){
            setShow(false);
        }else{
            const url = nextLink[0].split(';')[0]
            let suppressedURL = url.match(/<(.*?)>/)
            const after = new URL(suppressedURL[1]).searchParams.get("after");
            setAfter(after);
            setShow(true);
        }
    }

    const onShowMore = async()=>{
        await axios.get(`${REACT_APP_API_ENDPOINT}/groups/get-groups?limit=10&after=${after}`).then(res=>{
            let array = [];
            res.data.data.body.map(element => {
               array.push(element);
                return element;
            });
            setFilterList([...filterList,...array]);
            handleNextLink(res.data.data.nextLink);
        }).catch(error=>{
            console.log(error)
        })
    }


    const handleDeleteGroup = async(groupId,groupName)=>{

        confirm({ description: `This will permanently Delete group ${groupName}` })
        .then(async () => {
            await axios.delete(`${REACT_APP_API_ENDPOINT}/groups/delete-group/${groupId}`).then(res=>{
                getAllGroups();
                setOpenSuccess(true);
                setSuccessMessage('Group Deleted Successfully');
            },err=>{
                setErrorMessage(false)
                setSuccessMessage('Group Deletion Failed');
            })
        });
    }

    return (
        <>
          <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
        <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
        <MainCard title="Search Group" className={classes.card} id="Main">
            <Grid container justifyContent="flex-end">
                <Grid item xs={4}>
                <TextField
                    fullWidth
                    value={searchBy}
                    id="searchBy"
                    name="searchBy"
                    label="Search by Name..."
                    onChange={handleSearchByChange}
                    />
                </Grid> 
            </Grid>
            <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 650, padding: 0, margin: 0 }} aria-label="simple table" className={classes.table}> 
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Sr No.</TableCell> */}
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="left">Created on</TableCell>
                            <TableCell align="left">Updated on</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterList
                        .map((row, id) => (
                            <TableRow
                                key={id}
                            >
                                {/* <TableCell component="th" scope="row">
                                    {id+1}
                                </TableCell> */}
                                <TableCell align="left">{row.profile.name}</TableCell>
                                <TableCell align="left">{row.profile.description}</TableCell>
                                <TableCell align="left">{row.created}</TableCell>
                                <TableCell align="left">{row.lastUpdated}</TableCell>
                                <TableCell align="center" className={classes.ActionItems}>
                                    <Link to={`/groups/edit-group/${row.id}`} >
                                    <IconButton>
                                        <VisibilityOutlined className={classes.blueIcon}/>
                                    </IconButton>
                                    </Link>
                                    <Link to={`/groups/assign-group-user/${row.id}`}>
                                    <IconButton>
                                        <PersonAddAltIcon  className={classes.blueIcon} />
                                    </IconButton>
                                    </Link>
                                    <Link to='#' onClick={()=>handleDeleteGroup(row.id,row.profile.name)}>
                                    {/* <IconButton>
                                        <RemoveCircleOutlineTwoTone className={classes.redIcon}/>
                                    </IconButton> */}
                                    </Link>
                                   
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {
                    show?

                  <div className={classes.TableButton}>
                    <Button onClick={onShowMore}  className={classes.blueIcon} variant="text" size="large" style={{ fontSize: '17px', }} >
                      Show More
                    </Button>
                  </div>
                  :
                  <></>
                }
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
        </MainCard>
        </>
    )
}

export default AdminSearchGroup;