// material-ui
import {
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import ClearIcon from "@mui/icons-material/Clear";
// import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";


// project imports
import MainCard from "ui-component/cards/MainCard";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { useNavigate} from 'react-router-dom'
// import { useSelector } from "react-redux";

// ==============================|| SAMPLE PAGE ||============================== //

const useStyles = makeStyles((theme) => ({
  root: {

  },
  table: {
    "& .MuiTableRow-root": {
    },
    "& .MuiTableCell-body":{
      padding:theme.spacing(1),
      border: '1px solid #ececec',
      textAlign: 'center'
    },
    "& .MuiTableCell-head":{
        padding:theme.spacing(1),
        fontWeight:1000,
        background: '#4F81BD',
        color: 'white',
        borderTopLeftRadius: '6px',
      borderTopRightRadius:'6px',
    },
  },
  helperText: {
    color: "red",
    fontSize: "12px",
    position: "relative",
    left: "5%",
  },
  GridIconCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}));





function EditApp() {

  const classes = useStyles();
  const { Id } = useParams();  
  // const state = useSelector(state => state);
  // const roles = state.authUser.roles;
  

  const [AppData,setAppData] = useState([])
  const [profileLabel, setProfileLabel] = useState('')
  const [appCredential, setAppCredential] = useState();
  const [AppLabel, setAppLabel] = useState('')
  const [SettingRoles, setSettingRoles] = useState('');
  const { REACT_APP_API_ENDPOINT } = process.env
  const navigate = useNavigate()



  const initialValues = {
    client_uri: AppData?.client_uri && AppData?.client_uri !== null ? AppData.client_uri : "",
    logo_uri: AppData?.logo_uri && AppData?.logo_uri !== null ? AppData.logo_uri : "",
    redirect_uris: AppData?.redirect_uris && AppData?.redirect_uris !== null ? AppData?.redirect_uris : [],
    appLabel: AppLabel ? AppLabel : "",
    profileLabel: profileLabel ? profileLabel : "",
    clientId:appCredential?.oauthClient?.client_id?appCredential?.oauthClient?.client_id:""
  };

  const validationSchema = yup.object({
    client_uri: yup.string().required("Client URI is Required"),
    logo_uri: yup.string().required("Logo URI is Required"),
    redirect_uris: yup.array().of( yup.string().required("this field is required")),
    appLabel: yup.string().required("App Label is Required"),
    profileLabel: yup.string().required("profileLabel is Required"),
  });

  const onSubmit = (values) => {
    const redirect_Array = [];
    values.redirect_uris.map((item) => {
      redirect_Array.push(item);
      return redirect_Array;
    });
  };

  const getAppData = async () => {
     axios.get(`${REACT_APP_API_ENDPOINT}/apps/get-app/${Id}`)
     .then( res => {
       setAppCredential(res.data.credentials)
       setAppData(res.data.settings.oauthClient)
       setProfileLabel(res.data?.profile?.label)
       setAppLabel(res.data.label)
       if(res.data.settings?.notes){
        setSettingRoles(res.data.settings.notes)
       }else{
        setSettingRoles("")
       }
       
     })
  }
 
  useEffect(() => {
    getAppData()
    //eslint-disable-next-line
  }, [])

  return (
    <MainCard title="View Application">
      <Paper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, handleReset }) => (
            <Form className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack direction="column">
                    <Field
                      as={TextField}
                      label="Client URI"
                      disabled={true}
                      name="client_uri"
                      error={
                        errors.client_uri &&
                        touched.client_uri &&
                        Boolean(errors.client_uri)
                      }
                      value={values.client_uri}
                      fullWidth
                    />
                    <ErrorMessage name="client_uri">
                      {(msg) => <div className={classes.helperText}>{msg}</div>}
                    </ErrorMessage>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column">
                    <Field
                      disabled={true}
                      as={TextField}
                      label="Logo URI"
                      name="logo_uri"
                      error={
                        errors.logo_uri &&
                        touched.logo_uri &&
                        Boolean(errors.logo_uri)
                      }
                      fullWidth
                    />
                    <ErrorMessage name="logo_uri">
                      {(msg) => <div className={classes.helperText}>{msg}</div>}
                    </ErrorMessage>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column">
                    <Field
                     disabled={true}
                      as={TextField}
                      label="Profile Label"
                      name="profileLabel"
                      error={
                        errors.profileLabel &&
                        touched.profileLabel &&
                        Boolean(errors.profileLabel)
                      }
                      fullWidth
                    />
                    <ErrorMessage name="profileLabel">
                      {(msg) => <div className={classes.helperText}>{msg}</div>}
                    </ErrorMessage>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column">
                    <Field
                     disabled={true}
                      as={TextField}
                      label="App Label"
                      name="appLabel"
                      error={
                        errors.appLabel &&
                        touched.appLabel &&
                        Boolean(errors.appLabel)
                      }
                      fullWidth
                    />
                    <ErrorMessage name="appLabel">
                      {(msg) => <div className={classes.helperText}>{msg}</div>}
                    </ErrorMessage>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column">
                    <Field
                     disabled={true}
                      as={TextField}
                      label="Client Id"
                      name="clientId"
                      fullWidth
                    />
                  
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                      
                </Grid>
                <Grid item xs={6}>
                <FieldArray
                  name="redirect_uris"
                  render={(item) => {
                    const redirect_uris = values.redirect_uris;
                    return (
                      <Table  className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Redirect URL</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {redirect_uris && redirect_uris.length > 0
                          ? redirect_uris.map((uri, index) => {
                              return (
                                <>
                              <TableRow key={index} style ={ index % 2? { background : "white" }:{ background : "#eaf0ff" }}> 
                                <TableCell align="center">{uri}</TableCell>
                              </TableRow>
                                </>
                              );
                            })
                          : <>
                          <TableRow>
                          <TableCell align="center">None</TableCell>
                          </TableRow>
                          </>}
                          </TableBody>
                      </Table>
                    );
                  }}
                />
                </Grid>
                {
                  SettingRoles &&
                  <Grid item xs={6}>
 
                    <Table className={classes.table}>
                      <TableHead >
                        <TableRow>
                          <TableCell align="center">Roles</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                         SettingRoles && SettingRoles?.admin?.split(',')?.map((item,index)=>{
                            return(
                              <TableRow key={index} style ={ index % 2 ? { background : "white" }:{ background : "#eaf0ff" }}>
                                  <TableCell>{item}</TableCell>
                              </TableRow>
                            )
                          })
                        }
                      </TableBody>
                    </Table>
      
                  </Grid> 
                }
                <Grid item xs={12} >
                      <Typography style={{ color:'grey', opacity:'1', marginTop:"1%", fontSize:'1rem'}} variant='p' component='div'>
                       {/* {<b>Note: </b>} Please contact {<b>support@xxxx.com</b>} for adding new roles or any changes to the above fields. */}
                       Note: Please contact Unified support team for adding new roles or any changes to the above fields.
                      </Typography>
                </Grid>
               
              </Grid>

              <Grid container spacing={2} sx={{marginTop:"2px"}}>
                <Grid item xs={6}>
                  <Stack spacing={1} direction="row">
                    <Button type="button" variant="outlined" color="primary" onClick={() => navigate(-1) }>
                      Back
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>

    </MainCard>
  )
}

export default EditApp