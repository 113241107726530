import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import SearchGroup from 'views/pages/group/SearchGroup';
import CreateUser from 'views/pages/User/CreateUser';
import SearchApp from 'views/pages/Application/SearchApplication/SearchApp';
import EditApp from 'views/pages/Application/EditApplication/EditApp';
import AssignUserToApp from 'views/pages/Application/AssignUserToApplication/AssignUserToApp';
import AssignGroupToApp from 'views/pages/Application/AssignGroupToApplication/AssignGroupToApp';
// import DownloadSampleFile from 'views/pages/User/DownloadSampleFile';
import ImportUser from 'views/pages/User/ImportUser';
import SearchUser from 'views/pages/User/SearchUser';
import OidcApp from 'views/pages/Application/CreateApplication/OidcApp';
import EditUser from 'views/pages/User/EditUser/EditUser';
import Activate from 'views/pages/User/UserOperationsBulk/BulkOperation';
// import ImportModifyUser from 'views/pages/User/ImportModifyUser';
import AuthProvider from 'views/pages/authentication/auth-providers/AuthProvider';
import AdminSearchGroup from 'views/pages/group/AdminSearchGroup';
import NotFound from 'NotFound/NotFound';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// Application Route

const CreateApplication = Loadable(lazy( () => import('views/pages/Application/CreateApplication')))


// Groups Related 
const CreateGroup= Loadable(lazy(()=> import('views/pages/group/CreateGroup')))
const AssignGroupUser = Loadable(lazy(()=>import('views/pages/group/AssignGroupUser'))) 
const EditGroup = Loadable(lazy(()=>import('views/pages/group/EditGroup')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes ={
    path: '/',
    element:<MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element:  <AuthProvider route="/login"><DashboardDefault/></AuthProvider> 
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/application/create-application',
            element:<AuthProvider route="/login">  <CreateApplication /> </AuthProvider> 
        },
        {
            path: '/application/search-application',
            element:<AuthProvider route="/login"> <SearchApp /> </AuthProvider> 
        },
        {
            path: '/admin/search-application',
            element:<AuthProvider route="/login" role="ADMIN"> <SearchApp /> </AuthProvider> 
        },
        {
           path: '/application/create-application/oidc',
           element:<AuthProvider route="/login"> <OidcApp /> </AuthProvider> 
        },
        {
            path: '/application/assign-user-application/:AppName/:Id',
            element:<AuthProvider route="/login"> <AssignUserToApp /> </AuthProvider> 
        },
        {
            path: '/application/assign-group-application/:AppName/:Id',
            element: <AuthProvider route="/login"> <AssignGroupToApp /> </AuthProvider>
        },
        {
            path:'/application/edit-application/:Id',
            element:<AuthProvider route="/login"> <EditApp /> </AuthProvider> 
        },
        {
            path: '/groups/create-group',
            element: <AuthProvider route="/login"> <CreateGroup /> </AuthProvider>
        },
        {
            path: '/groups/search-group',
            element:<AuthProvider route="/login" > <SearchGroup /> </AuthProvider> 
        },
        {
            path: '/admin/search-group',
            element:<AuthProvider route="/login" role="ADMIN"> <AdminSearchGroup /> </AuthProvider> 
        },
        {
            path: '/user/create-user',
            element: <AuthProvider route="/login"> <CreateUser /> </AuthProvider>
        },
        {
            path:'/groups/assign-group-user/:groupId',
            element:<AuthProvider route="/login"> <AssignGroupUser/> </AuthProvider>
        },
        {
            path:'/groups/edit-group/:groupId',
            element:<AuthProvider route="/login"> <EditGroup/> </AuthProvider>
        },
        // {
        //     path:'/user/download-sample-file',
        //     element:<AuthProvider route="/login"> <DownloadSampleFile/> </AuthProvider>
        // },
        {
            path:'/user/import-user',
            element: <AuthProvider route="/login"> <ImportUser/> </AuthProvider>
        },
        // {
        //     path:'/user/import-modify-user',
        //     element: <AuthProvider route="/login"> <ImportModifyUser/> </AuthProvider>
        // },
        {
            path:'/user/list-users',
            element: <AuthProvider route="/login"> <SearchUser /> </AuthProvider>
        },
        {
            path:'/admin/list-users',
            element: <AuthProvider route="/login" role="ADMIN"> <SearchUser /> </AuthProvider>
        },
        {
           path:'/user/list-users/user-operations/:type',
           element:  <AuthProvider route="/login"> <Activate /> </AuthProvider>
        },
        {
            path:'/user/edit-user/:UserID',
            element: <AuthProvider route="/login"> <EditUser /> </AuthProvider>
        },
        {
            path:'*',
            element: <NotFound />
        }
        
    ]
};

export default MainRoutes;
