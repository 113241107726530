// material-ui
// import { CircularProgress} from '@mui/material';
import './circularLoader.css'





// ==============================|| LOADER ||============================== //
const Loader = () => {
    return(
      <div className="spinner">
            <div className="circle one"></div>
            <div className="circle two"></div>
            <div className="circle three"></div>
      </div>
    )
}

export default Loader; 