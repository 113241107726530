import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";

import { ConfirmProvider } from "material-ui-confirm";
import { Security } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { useNavigate } from "react-router";
import React from "react";
import { store } from "store";
import { CLOSE_MENU } from "actions/MenuActions";
import Interceptor from "./interceptor/interceptor";
import ErrorBoundary from "ErrorBoundary/ErrorBoundary";
Interceptor.interceptor(store);

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.menu);

  const onAuthRequired = () => {
    navigate("/login");
  };

  const {
    REACT_APP_OKTA_CLIENTID,
    REACT_APP_OKTA_ISSUER,
    REACT_APP_OKTA_REDIRECTURI,
    REACT_APP_OKTA_POST_LOGOUT_REDIRECTURI,
  } = process.env;

  const oktaAuth = new OktaAuth({
    issuer: REACT_APP_OKTA_ISSUER,
    clientId: REACT_APP_OKTA_CLIENTID,
    redirectUri: REACT_APP_OKTA_REDIRECTURI,
    postLogoutRedirectUri: REACT_APP_OKTA_POST_LOGOUT_REDIRECTURI,
    pkce: true,
    onAuthRequired: onAuthRequired,
    scopes: ["profile", "email", "openid", "offline_access", "wsp-scope"],
  });

  const restoreOriginalUri = async () => {
    navigate("/dashboard/default");
  };

  const dispatch = useDispatch();
  document.getElementById("Main")?.addEventListener(
    "click",
    (e) => {
      if (menuState.menu !== "") {
        dispatch({ type: CLOSE_MENU });
        // console.log("Dispatched!!!")
      }
    },
    { once: true }
  );

  //   function captchaVisibility() {
  //     let Element = document.getElementsByClassName("grecaptcha-badge");
  //     Element[0].style?.visibility = "hidden";
  //   }

  //   useEffect(() => {
  //     setTimeout(() => {
  //       captchaVisibility();
  //     }, 150);
  //   }, []);

  return (
    <>
      <ErrorBoundary>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <ConfirmProvider>
              <CssBaseline />
              <NavigationScroll>
                <Security
                  restoreOriginalUri={restoreOriginalUri}
                  oktaAuth={oktaAuth}
                >
                  <Routes />
                </Security>
              </NavigationScroll>
            </ConfirmProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
