import {dashboardActions} from '../actions/dashboardActions'

export const dashboardData = {
    appCount : null,
    groupCount: null,
    totalUserCount: null,
    adminLogs: null,
    groupStats: [],
    refreshData : false
};

const dashBoardReducer  = (state=dashboardData,action)=>{
    switch(action.type){
        case  dashboardActions.APP_COUNT:
            return {
                ...state,
                // authState:action.payload.authState,
                appCount:action.payload,
            }
        case dashboardActions.GROUP_COUNT:
            return{
                ...state,
                // authState: {},
                groupCount: action.payload,
            }
        case dashboardActions.TOTAL_USER_COUNT:
            return{
                ...state,
                totalUserCount:action.payload
            }
        case dashboardActions.ADMIN_LOGS:
            return{
                ...state,
                adminLogs:action.payload
            }
        case dashboardActions.GROUP_STATS:
            return{
                ...state,
                groupStats:action.payload 
            }
        case dashboardActions.REFRESH_DATA:
             return{
                appCount : action.payload.appCount,
                groupCount: action.payload.groupCount,
                totalUserCount: action.payload.totalUserCount,
                adminLogs: action.payload.adminLogs,
                groupStats: action.payload.groupStats,
                refreshData: action.payload
             }
        default:
            return state
    }
}

export default dashBoardReducer;