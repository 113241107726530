import MinimalLayout from "layout/MinimalLayout"
import Activation from "views/pages/Application/UnprotectedRoute/Activation"

const PublicRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children:[
        {
            path:'/user-reactivation',
            element:<Activation />
        }
    ]
   
}

export default PublicRoutes
