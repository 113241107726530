import authUserReducer from 'reducers/authUserReducer';
import menuReducer from 'reducers/menuReducer';
import dashBoardReducer from 'reducers/dashBoardReducer';
import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    authUser: authUserReducer,
    dashboard: dashBoardReducer,
    menu:menuReducer
});

export default reducer;
