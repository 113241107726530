import {  Button, FormControl, Grid, MenuItem, Select, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Loader from "ui-component/Loader";
import FailureNotification from "ui-component/FailureNotification";
import SuccessNotification from "ui-component/SuccessNotification";
import * as yup from "yup";
import language from "./language";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const useStyles = makeStyles((theme) => ({
  root: {},
  loader: {
    margin: "0.5%",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  container:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin:'30px',
    flexDirection:'column'
  },
 card:{
  position: "relative",
  display: "flex",
  flexDirection: "column",
  maxWidth: "450px",
  width: "100%",
  textAlign: "center",
  padding: "15px",
  margin: "20px",
 },
 image:{
  marginBottom: "1.5rem",
  verticalAlign: "middle",
  borderStyle: "none",
 },
 header:{
  color: "#0C2D83",
  marginBottom: "16px",
  fontWeight: "bold",
  marginTop: "0px",
  lineHeight: "1.2",
  fontSize: "1.75rem",
 },
 input:{
  position: "relative",
  boxSizing: "border-box",
  height: "auto",
  padding: "10px",
  fontSize: "16px",
 },

 header5:{
  color: "#0071CA",
  marginTop: "5px",
  marginLeft: "16px",
  marginBottom: '1rem!important',
  fontSize:'1.25rem',
  fontWeight:'500',
  lineHeight:'1.2'
 },
 show:{
  display:'block !important'
 },
 dropdownMenu:{
   position: 'absolute',
    top: "100%",
    left: '0',
    zIndex: '1000',
    display: 'none',
    float: 'left',
    minWidth: '10rem',
    padding: '0.5rem 0',
    margin: '0.125rem 0 0',
    fontSize: '1rem',
    color: '#212529',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0,0,0,.15)',
    borderRadius: '0.25rem',
 },
 dropdownItem:{
        display: 'block',
        width: '100%',
        padding: '0.25rem 1.5rem',
        clear: 'both',
        fontWeight: '400',
        color: '#212529',
        textAlign: 'inherit',
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        border: '0',
 },
 dropdown:{
   position:'relative'
 },
 flagIcon:{
  backgroundSize: 'contain',
  backgroundPosition: '50%',
  backgroundRepeat: 'no-repeat',
  position: 'absolute',
  width: '1.33333333em',
  lineHeight: '1em',
  height:'30px'
 },
 flagIconUs:{
  backgroundImage:`url(https://partnersso.ite.mypepsico.com/mylogin/resources/flags/4x3/us.svg)`,
 },
 dropdownToggle:{
  color: '#007bff',
  textDecoration: 'none',
  backgroundColor: 'transparent',
  display:'block',
  padding:'0.5rem 1rem',
  cursor:'pointer',
 },
 dropIcon:{
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '0.255em',
    verticalAlign: '0.255em',
    content: "Rohith",
    borderTop: '0.3em solid',
    borderRight: '0.3em solid transparent',
    borderBottom:' 0',
    borderLeft: '0.3em solid transparent',
 },
 formControl: {
  margin: theme.spacing(1),
  minWidth: 120,
},
select: {
  height: "35px", // Adjust the height as needed
  width: "200px", // Adjust the width as needed
},
label: {
  transform: "translate(0, 1.5px) scale(0.75)", // Adjust label positioning as needed
},
 
 
}));

const countries = [
 
  {
    code: 'en_US',
    label: 'English',
    flag: "https://partnersso.ite.mypepsico.com/mylogin/resources/flags/4x3/us.svg",
    value: 'en-US'
  },
  {
    code: 'zh_CN',
    label: '简体中文',
    flag: "https://partnersso.ite.mypepsico.com/mylogin/resources/flags/4x3/cn.svg",
    value: 'zh-CN'
  },
  {
    code: 'zh_TW',
    label: ' 繁體中文',
    flag: "https://partnersso.ite.mypepsico.com/mylogin/resources/flags/4x3/tn.svg",
  },
  { code: 'ru_RU', label: 'Русском', flag: "https://partnersso.ite.mypepsico.com/mylogin/resources/flags/4x3/ru.svg",
   value:'ru-RU'
},
  { code: 'es_ES', label: "Español", flag: "https://partnersso.ite.mypepsico.com/mylogin/resources/flags/4x3/es.svg",value:'es-ES'},
  { code: 'pt_BR', label: "Português", flag: "https://partnersso.ite.mypepsico.com/mylogin/resources/flags/4x3/br.svg",value:'pt-BR'},
  { code: 'uk_UA', label: "Українська ", flag: "https://partnersso.ite.mypepsico.com/mylogin/resources/flags/4x3/ua.svg",value:'uk-UA'},

];

const defaultLanguage = window?.navigator?.language

let lang = countries.filter(item => item.value === defaultLanguage).length > 0 
? countries.filter( item => item.value === defaultLanguage)[0]?.code : "en_US"



function Activation() {
  const {REACT_APP_API_ENDPOINT} = process?.env
  const [Loader1, setLoader] = useState(false);
  const classes = useStyles();
  const [openError, setopenError] = React.useState(false);
  const [ErrorMessage, setErrorMessage] = React.useState("");
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [SuccessMessage, setSuccessMessage] = React.useState("");
  const [selectedCountry1, setSelectedCountry1] = useState(lang);
  const [selectedCountry, setSelectedCountry] = useState(selectedCountry1);
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [languageData, setLanguageData] = useState("")

  const initialValues = {
    email:''
  };

  const validationSchema = yup.object({
   email: yup.string().required(languageData?.EmailError).email(languageData?.EmailValid).test("Domain validation", "Please Contact Associate team to create pepsico Email ID's ", (value) => {
    const Domain = value?.split('@')[1]?.toLowerCase()
    const PepsiDomain = "pepsico.com"
    // console.log({Domain},{PepsiDomain})
    if(Domain === PepsiDomain){
        return false
    }else{
        return true
    }
})
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, props) => {
      console.log({values},{props})
      let reCaptcha_token = ""
      if (!executeRecaptcha) {
        setopenError(true)
        setErrorMessage("Token is Not Valid")
     }
     reCaptcha_token = await executeRecaptcha("");
      
      ActivateEmail(values.email, reCaptcha_token)
    }
  })

  useEffect(() => {
  let langData =  language(selectedCountry1)
  setLanguageData(langData)

  },[selectedCountry1])


  const ActivateEmail = async(email, reCaptchaToken) => {
    setLoader(true)
   await axios.post(`${REACT_APP_API_ENDPOINT}/activate-user?email=${email}&locale=${selectedCountry}&reCaptchaToken=${reCaptchaToken}`)
   .then(res => {
    setLoader(false)
    if(res.data.responseStatus === "SUCCESS"){
      setOpenSuccess(true)
      setSuccessMessage(languageData?.ActivationSuccess)
    }else if(res.data.responseStatus === "FAILURE"){
      setopenError(true)
      setErrorMessage(languageData?.ActivationFail)
    }
   }).catch(err => {
    setLoader(false)
     setopenError(true)
     setErrorMessage(err)
   })
  }

  //***************** || Alert Handle Close  || ************************* */ 

  const handleCloseAlertError = () => {
    setErrorMessage("");
    setopenError(false);
};

const handleCloseAlertSuccess = () => {
    setSuccessMessage("");
    setOpenSuccess(false);
};


const selectCountryHandler = (e) => setSelectedCountry(e.target.value);

  const handleChange = (event) => {
    setSelectedCountry1(event.target.value);
    setSelectedCountry(event.target.value)
  };

  return (
    <>
      <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage}/>
      <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
      {Loader1 ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : null}
      <div
        className={classes.container}
      >
        <Grid container>
           <Grid item xs={12} style={{display:'flex',alignItems:'center', justifyContent:'end'}}>
               <Grid item xs={6}></Grid>
                <Grid item xs={6} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                <p style={{paddingRight:'30px'}}>{languageData?.ViewThisPage}</p>
                <FormControl className={classes.formgroup}>
                  <Select
                    value={selectedCountry1}
                    onChange={handleChange}
                    className={classes.select}
                  >
                    
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        <img
                          src={country.flag}
                          alt={country.label}
                          style={{ marginRight: 8, width: 20, height: 10 }}
                        />
                        {country.label}
                      </MenuItem>
                    ))}
                  </Select>
               </FormControl>
                </Grid>
           </Grid>
        </Grid>
          <form
            className={classes.card}
          >
            <img
              src="https://partnersso.ite.mypepsico.com/mylogin/resources/img/pepsico_pepext.jpg"
              width="100%"
              alt="Pepsi Mail"
              className={classes.image}
            />
            <h1
              className={classes.header}
            >
              {languageData?.FirstTimeUser}{" "}
            </h1>
            <TextField
              fullWidth
              required
              id='email'
              name='email'
              label={languageData?.EmailAddress}
               value={formik.values.email}
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
               helperText={formik.touched.email && formik.errors.email}
               autoComplete="false"
               
               >
            </TextField>
            <br />
            <br />
            <div className={classes.formgroup}
            >
              <Grid container sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Grid item xs={6} >
                  <h5
                    className={classes.header5}
                    style={{marginTop:'15px'}}
                    >
                    {languageData?.ReceiveEmails}
                  </h5>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formgroup}>
                  <Select
                    value={selectedCountry}
                    onChange={selectCountryHandler}
                    className={classes.select}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        <img
                          src={country.flag}
                          alt={country.label}
                          style={{ marginRight: 8, width: 20, height: 10 }}
                        />
                        {country.label}
                      </MenuItem>
                    ))}
                  </Select>
               </FormControl>
                </Grid>
              </Grid>
            </div>
            <br />
            <br />
            <Stack>
              <Button
                type="button "
                variant="contained"
                color="primary"
                size="large"
                onClick={formik.handleSubmit}
              >
                {languageData?.Submit}
              </Button>
            </Stack>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="footer">
		        <p className="message" style={{marginTop:'0'}}>© {new Date().getFullYear()}   PepsiCo, Inc. All Rights Reserved</p>
	          </div>
          </form>
      </div>
    </>
  );
}

export default Activation;
