// import { useOktaAuth } from "@okta/okta-react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";


export const AuthProvider = ({children, route,isLogin,role})=>{
    // const {oktaAuth} = useOktaAuth()
    // const navigate = useNavigate()
    let authInitState = {authState:"",isAuthenticated:false};
    const authState = useSelector((state)=> state.authUser);
     if(localStorage.getItem('okta-token-storage')){
        const data = JSON.parse(localStorage.getItem('okta-token-storage'));
        const auth = {
            accessToken:data.accessToken,
            idToken:data.idToken
        }
        authInitState = {
            authState : auth,
            isAuthenticated : data.isAuthenticated
        }
    }
    // else if(authState){
    //     authInitState = {
    //         authState : authState.authState,
    //         isAuthenticated : authState.isAuthenticated
    //     }
    // }

    
    if(authInitState?.authState?.accessToken){
        // const ExpirationTime = authInitState?.authState?.accessToken?.expiresAt * 1000
        // const CurrentTime = new Date().getTime()
        if(isLogin){
            return <Navigate to='/dashboard/default' />
        }else{
    //         setTimeout( async() =>  {
    //         await oktaAuth.tokenManager.renew('accessToken')
    //         .then( function(newToken){
    //             console.log({newToken})
    //         }).catch( err => console.log({err}))
    //  }, 10000)
            if(authState?.roles?.indexOf("partneriam-admin")!==-1){
                return children;
            }else{
                if(role && role==="ADMIN"){
                    return <Navigate to='/unauthorised' />;
                }else{  
                    return children;
                }
               
            }
        }
    }else{
        if(isLogin){
            return children;
        }else{
            return <Navigate to={route} />
        }
        
    } 
}

export default AuthProvider;