import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import AuthProvider from 'views/pages/authentication/auth-providers/AuthProvider'
import Logout from 'views/pages/authentication/auth-providers/Logout';
import Unauthorised from 'views/pages/error-page/unauthorised';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //




const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
    
        {
            path: '/pages/login/login3',
            element: <AuthLogin3 />
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />
        },
        {
            path:'/',
            element: <AuthProvider isLogin="true"><AuthLogin3/></AuthProvider>

        },
        {
            path:'/login',
            element: <AuthProvider isLogin="true"><AuthLogin3/></AuthProvider>

        },
        {
            path: '/logout',
            element: <Logout />
        },
        {
            path:'/unauthorised',
            element: <Unauthorised/>

        }
    ] 
};

export default AuthenticationRoutes;
