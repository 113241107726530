import React, {  useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPersonCircleCheck, faPersonCircleXmark, faPersonCircleMinus, faUserMinus, faKey } from "@fortawesome/free-solid-svg-icons"

import axios from "axios";
import {Button, Grid, IconButton, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Edit } from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'

import MainCard from "ui-component/cards/MainCard";
import { IconSearch } from "@tabler/icons";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "ui-component/Loader";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as yup from 'yup';
import FailureNotification from "ui-component/FailureNotification";
import SuccessNotification from "ui-component/SuccessNotification";
import debounce from 'lodash.debounce'


const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-6hp17o-MuiList-root-MuiMenu-list": {
      width: '160px'
    }
  },
  table: {
    "& .MuiTableRow-root": {
      border: '1px solid #ececec'
    },
    "& .MuiTableCell-body": {
      padding: '5px 10px',
      border: '1px solid #ececec'
    },
    "& .MuiTableCell-head": {
      padding:theme.spacing(1),
      border: '1px solid #ececec',
      fontWeight:1000,
      background: '#4F81BD',
      color: 'white'
    },
  },
  ActionItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",

  },
  TableButton: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
  },
  TopBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },

  card: {
    "& .MuiPaper-root": {
      marginTop: theme.spacing(2)
    },
  },
}));



function SearchUser() {

  const classes = useStyles();
  const navigate = useNavigate()
  const [FilteredList, setFilterList] = useState([]);
  const [PageAfter, setPageAfter] = useState(null)
  const [ShowMoreButton, setShowMoreButton] = useState(false)
  const [SearchInputValue, setSearchInputValue] = useState("")
  const [FilterSearch, setFilterSearch] = useState("")
  const [status, setStatus] = useState("ALL");
  const [disable, setDisable] = React.useState(false);

   //*******|| ALERT MESSAGE FUNCTIONALITY || ********* */

   const [openError, setopenError] = useState(false);
   const [ErrorMessage, setErrorMessage] = useState("");
   const [openSuccess, setOpenSuccess] = useState(false);
   const [SuccessMessage, setSuccessMessage] = useState("");
   const [Loader1, setLoader] = useState(false)

  //***********|| ROLE MANAGEMENT AND APPLICATION IDS || */

  const [appArray, setAppArray] = React.useState(null);
  const [oktaAppID, setOktaAppID] = useState(null)
  const state = useSelector(state => state);
  const roles = state.authUser.roles;

  let roleID = roles.map((role) => role.split("partneriam-")[1]);
  let adminFind = roleID.includes("admin");

  let initValue = {
    appId: '',
  }

  let validationObject = {
    appId: yup.string()
      .required('Application ID is required')
  }

  const validationSchema = yup.object().shape(validationObject)

  const formik = useFormik({
    initialValues: initValue,
    validationSchema: validationSchema,
    validateOnBlur: true
  })

  const getApps = async () => {
    await axios
      .post(`${REACT_APP_API_ENDPOINT}/apps/get-apps`,
        {
          "filterType": 2,
          "value": "ACTIVE"
        }
      )
      .then((res) => {
        let appOptions = []
        let appOptionsRoles = []
        res.data.data.body?.map((item) => {
          appOptions.push({
            'label': item.label,
            'id': item.id
          })
          return item
        })
        roleID.map((value) => {
          appOptions.map((item) => {
            if (item.id === value) {
              appOptionsRoles.push({
                'label': item.label,
                'id': item.id
              })
            }
            return item
          })
          return value
        })
        setAppArray(appOptionsRoles)
        if(appOptionsRoles.length === 1){
          formik.setFieldValue("appId", appOptionsRoles[0].id)
        }
      }
      )
      .catch((err) => {
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      })
  }

  React.useEffect(() => {
    if (!adminFind) {
      getApps()
    }
    //eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    setOktaAppID(formik.values.appId)
  }, [formik.values.appId])

  const getAssignedUsersOfApp = async() => {
    await axios
    .get(`${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${oktaAppID}?limit=10`)
    .then((res) => 
    {
      setShowMoreButton(true)
        // setUserData( res.data.data.body);
        setFilterList(res.data.data.body);
        if (res.data.data.nextLink.length === 1) {
          setShowMoreButton(false)
        }
        setStatus(status)
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
        setLoader(false)
    }
    )
    .catch((err) => {
      if(err.response.status === 429){
        setopenError(true);
        setErrorMessage('Too Many Requests')
    }else{
        console.log(err)
    }
    })

  }

  React.useEffect(() => {
    if(oktaAppID && !adminFind){
      getAssignedUsersOfApp()
    } 
    //eslint-disable-next-line
  }, [oktaAppID])
  

 

  //*******|| MORE ACTIONS BUTTON || ********* */

  const [LinkElement, setLinkElement] = useState(null)
  const open = Boolean(LinkElement);

  const handleClick1 = (event) => {
    setLinkElement(event.currentTarget);
  };
  // useEffect(() =>{
  //   console.log({status})
  // },[status])

  const handleClose = async (ele) => {
    setLoader(true)
    setLinkElement(null)
    setStatus(ele.target.value)
    if (ele.target.value === "ALL") {
      setFilterSearch("")

      adminFind ? getUsers() : getAssignedUsersOfApp()
    } else {

      adminFind ? (
      await axios.post(`${REACT_APP_API_ENDPOINT}/users/get-users?limit=10`, {
        filterType: '1',
        value: ele.target.value
      }).then(res => {
        setFilterSearch(ele.target.value)
        setFilterList([])
        setPageAfter(null)
        setShowMoreButton(true)
        setFilterList(res.data.data.body);
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
        if (res.data.data.nextLink.length === 1) {
          setShowMoreButton(false)
        }
        setLoader(false)
      }).catch(err => {
        setLoader(false)
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      })
      ) : (
        await axios.get(`${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${oktaAppID}?limit=10`)
        .then(res => {
          setFilterSearch(ele.target.value)
          setFilterList([])
          setPageAfter(null)
          setShowMoreButton(true)
          setFilterList(res.data.data.body);
          const url = res.data.data.nextLink[0].split(';')[0]
          let suppressedURL = url.match(/<(.*?)>/)
          const after = new URL(suppressedURL[1]).searchParams.get("after")
          setPageAfter(after)
          if (res.data.data.nextLink.length === 1) {
            setShowMoreButton(false)
          }
          setLoader(false)
        }).catch(err => {
          setLoader(false)
          if(err.response.status === 429){
            setopenError(true);
            setErrorMessage('Too Many Requests')
        }else{
            console.log(err)
        }
        })
      )
    }

  };


  //************ || ENVIRONMENT VARIABLE || ************* */


  const { REACT_APP_API_ENDPOINT } = process.env


  //**************** || GETTING ALL USERS || ***************** */


  const getUsers = async (status1) => {
    setLoader(true)
    await axios
      .post(`${REACT_APP_API_ENDPOINT}/users/get-users?limit=10`, {
        filterType: status === "ALL" ? '0': '1',
        value:status1?status1:status
      })
      .then((res) => {
        setShowMoreButton(true)
        // setUserData( res.data.data.body);
        setFilterList(res.data.data.body);
        if (res.data.data.nextLink.length === 1) {
          setShowMoreButton(false)
        }
        setStatus(status)
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      });
  };

  //*************** || GET USERS AFTER CLICKING ON SHOW MORE PAGINATION || ****************** */

  const getUsersAfter = async () => {
    let PageUrl
    if (PageUrl !== null && SearchInputValue !== "") {
      PageUrl = `${REACT_APP_API_ENDPOINT}/users/get-users?limit=10&after=${PageAfter}&searchKeyword=${SearchInputValue}`
    } else if (PageUrl !== null && FilterSearch !== "") {
      if (PageAfter !== null) {
        PageUrl = `${REACT_APP_API_ENDPOINT}/users/get-users?limit=10&after=${PageAfter}`
      } else {
        PageUrl = `${REACT_APP_API_ENDPOINT}/users/get-users?limit=10`
      }
    } else {
      if (PageAfter !== null) {
        PageUrl = `${REACT_APP_API_ENDPOINT}/users/get-users?limit=10&after=${PageAfter}`
      } else {
        PageUrl = `${REACT_APP_API_ENDPOINT}/users/get-users?limit=10`
      }
    }
    setLoader(true)

    await axios
      .post(PageUrl, FilterSearch ? { filterType: '1', value: FilterSearch } : { filterType: '0' })
      .then((res) => {
        res.data.data.body.map((item) => {
          FilteredList.push(item)
          return item
        })
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
        if (res.data.data.nextLink.length === 1) {
          setShowMoreButton(false)
        }
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      });
  }


  useEffect(() => {
    if(adminFind){
      getUsers();
    }
    
    //eslint-disable-next-line
  }, []);

  //*************** || GET USERS AFTER CLICKING ON SHOW MORE PAGINATION WITH ROLES|| ****************** */

  const getUsersAfterRoles = async () => {
    let PageUrl
    if (PageUrl !== null && SearchInputValue !== "") {
      PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${oktaAppID}?limit=10&after=${PageAfter}&q=${SearchInputValue}`
    } else if (PageUrl !== null && FilterSearch !== "") {
      if (PageAfter !== null) {
        PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${oktaAppID}?limit=10&after=${PageAfter}`
      } else {
        PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${oktaAppID}?limit=10`
      }
    } else {
      if (PageAfter !== null) {
        PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${oktaAppID}?limit=10&after=${PageAfter}`
      } else {
        PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${oktaAppID}?limit=10`
      }
    }
    setLoader(true)

    await axios
      .get(PageUrl)
      .then((res) => {
        res.data.data.body.map((item) => {
          FilteredList.push(item)
          return item
        })
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
        if (res.data.data.nextLink.length === 1) {
          setShowMoreButton(false)
        }
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      });
  }


  //******************** ||  DEBOUNCING SEARCH HANDLE CHANGE ||*************************** || */

  const handleSearchByChange = async (event) => {
    
    setLoader(true)
    let { value } = event.target
    setSearchInputValue(event.target.value)
    let HitUrlApi
    if (value.trim() !== "") {
      HitUrlApi = `${REACT_APP_API_ENDPOINT}/users/get-users?limit=10&searchKeyword=${value}`
    }
    if(value.trim() === "" && adminFind){
      HitUrlApi = `${REACT_APP_API_ENDPOINT}/users/`
    }
    
    if (value.trim() !== "") {
      await axios.post(HitUrlApi, {
        filterType: status==="ALL" ? '0' :'1',
        value: status
      }).then(res => {
        setLoader(false)
        setShowMoreButton(true)
        setStatus(status)
        setFilterList(res.data.data.body);
        if (res.data.data.nextLink.length === 1) {
          setShowMoreButton(false)
        }
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
      }).catch(err => {
        setLoader(false)
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      })
    } else {
      getUsers()
    }
  }  
  // console.log({ SearchInputValue })

    //******************** ||  DEBOUNCING SEARCH HANDLE CHANGE ROLES||*************************** || */

    const handleSearchByChangeRoles = async (event) => {
      let oktaAppID = event.target.id
      setLoader(true)
      let { value } = event.target
      setSearchInputValue(event.target.value)
      let HitUrlApi
      if (value.trim() !== "") {
        HitUrlApi = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${oktaAppID}?limit=10&q=${value}`
      }
      if (value.trim() !== "") {
        await axios.get(HitUrlApi).then(res => {
          setLoader(false)
          setShowMoreButton(true)
          setStatus(status)
          setFilterList(res.data.data.body);
          if (res.data.data.nextLink.length === 1) {
            setShowMoreButton(false)
          }
          const url = res.data.data.nextLink[0].split(';')[0]
          let suppressedURL = url.match(/<(.*?)>/)
          const after = new URL(suppressedURL[1]).searchParams.get("after")
          setPageAfter(after)
        }).catch(err => {
          setLoader(false)
          if(err.response.status === 429){
            setopenError(true);
            setErrorMessage('Too Many Requests')
        }else{
            console.log(err)
        }
        })
      } else {
        getAssignedUsersOfApp()
      }
    }  
    // console.log({ SearchInputValue })

  //*************** || SEARCH RESULT AFTER PAGINATION  || *************** */

  const handleSearchByChange1 = async (status) => {
    setLoader(true)
    let HitUrlApi
    if (SearchInputValue !== "") {
      HitUrlApi = `${REACT_APP_API_ENDPOINT}/users/get-users?limit=10&searchKeyword=${SearchInputValue}`
    }
    if (SearchInputValue !== "") {
      await axios.post(HitUrlApi, {
        filterType: '1',
        value: status
      }).then(res => {
        setLoader(false)
        setShowMoreButton(true)
        setStatus(status)

        if (res.data.data.nextLink.length === 1) {
          setShowMoreButton(false)
        }
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
        setFilterList(res.data.data.body);
      }).catch(err => {
        setLoader(false)
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      })
    } else {
      getUsers()
    }
  }

  //*************** || SEARCH RESULT AFTER PAGINATION ROLES || *************** */

  const handleSearchByChange1Roles = async (status) => {
    setLoader(true)
    let HitUrlApi
    if (SearchInputValue !== "") {
      HitUrlApi = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${oktaAppID}?limit=10&q=${SearchInputValue}`
    }
    if (SearchInputValue !== "") {
      await axios.get(HitUrlApi).then(res => {
        setLoader(false)
        setShowMoreButton(true)
        setStatus(status)

        if (res.data.data.nextLink.length === 1) {
          setShowMoreButton(false)
        }
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
        setFilterList(res.data.data.body);
      }).catch(err => {
        setLoader(false)
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      })
    } else {
      getAssignedUsersOfApp()
    }
  }


  //************** ||  USER OPERATIONS    || ******************* */

  const UserOperation = async (userId, operation, status) => {
    setDisable(true);
    await axios.post(`${REACT_APP_API_ENDPOINT}/users/operations-user/${userId}?operationType=${operation}`)
      .then(res => {
        setOpenSuccess(true)
        setSuccessMessage(res.data.messgae)
        //setStatus(status)
        let timer
        if (SearchInputValue !== "") {
          clearTimeout(timer)
          timer = setTimeout(() => {
            adminFind?
            handleSearchByChange1(status)
            :
            handleSearchByChange1Roles(status)
          }, 1000)
        } else {
          timer = setTimeout(() => {
            adminFind?
            handleSearchByChange1(status)
            :
            handleSearchByChange1Roles(status)
          }, 1000)
        }
        setDisable(false);
      }).catch(error => {
        setopenError(true);
        if(error.response.status === 429){
          setErrorMessage('Too Many Requests')
      }else{
        if(error?.response?.data?.errorCauses.length>0){
          setErrorMessage(error?.response?.data?.errorCauses[0].errorSummary);
        }else{
          setErrorMessage(error.response.data.errorSummary);
        }
      }
        setDisable(false);
      })
  }


  //***************** || Alert Handle Close  || ************************* */ 

  const handleCloseAlertError = () => {
    setErrorMessage("");
    setopenError(false);
  };

  const handleCloseAlertSuccess = () => {
    setSuccessMessage("");
    setOpenSuccess(false);
  };

  //********** || DELETE USER ||  **************/

  const DeleteUser = async (userId) => {
    setDisable(true);
    await axios.post(`${REACT_APP_API_ENDPOINT}/users/already-deactivated-delete-user/${userId}`)
      .then(res => {
        setOpenSuccess(true)
        setSuccessMessage(res.data.messgae)
        setStatus("ALL")
        getUsers();
        setDisable(false);
      }).catch(err => {
        setopenError(true);
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
        setErrorMessage(err.response.data.errorSummary);
        setDisable(false);
      }
      })
  }

  //**************** || DEBOUNCING IMPLEMENTATION FOR SEARCH  || **************** */

  // const debounce1 = function (func, delay) {
  //   let timer;
  //   return function () {     //anonymous function
  //     const context = this;
  //     const args = arguments;
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func.apply(context, args)
  //     }, delay);
  //   }
  // }
  //eslint-disable-next-line
  // const BetterFunction = useCallback(debounce(handleSearchByChange, 500), [])
  //eslint-disable-next-line
  const BetterFunction = useMemo( () => debounce(handleSearchByChange,500), [status])
 
  //eslint-disable-next-line
  // const BetterFunctionRoles = useCallback(debounce1(handleSearchByChangeRoles, 500), [])
  //eslint-disable-next-line
  const BetterFunctionRoles = useMemo(() => debounce(handleSearchByChangeRoles, 500), [oktaAppID])


  //************** || Handling Routes  || ************* */

  const handleRoute = (Route) => {
    setLinkElement(null)
    navigate(`/user/list-users/user-operations/${Route}`)
  }

  const handleApplicationExportUsers = async () => {
    await axios.post(`${REACT_APP_API_ENDPOINT}/apps/get-application-users/${oktaAppID}?q=${SearchInputValue}`,{},{
      responseType: 'blob',
    }).then( response => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], {
        type: type,
        encoding: 'UTF-8',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Users.xlsx';
      link.click();
    }).catch((err) => {
      if(err.response.status === 429){
        setopenError(true);
        setErrorMessage('Too Many Requests')
    }else{
        console.log(err)
    }
    });
  }

  const handleExportUsers = async () => {
    await axios.post(`${REACT_APP_API_ENDPOINT}/users/get-users-excel?searchKeyword=${SearchInputValue}`,{
      filterType: status === "ALL" ? '0': '1',
      value:status
    },{
      responseType: 'blob',
    }).then( response => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], {
        type: type,
        encoding: 'UTF-8',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Users.xlsx';
      link.click();
    }).catch((err) => {
      if(err.response.status === 429){
        setopenError(true);
        setErrorMessage('Too Many Requests')
    }else{
        console.log(err)
    }
    });
  }
  return (
    <>
      <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
      <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
      {
        Loader1 ?
          <div style={{ margin: '0.5%' }}>
            <Loader />
          </div>
          :
          null
      }
      <MainCard title="List Users" className={classes.card} id="Main">

        

        {/* {oktaAppID ? <> */}
          <Grid container spacing={4} >
              {adminFind ? <></> : <>
                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    fullWidth
                    name='appId'
                    label='Application ID'
                    SelectProps={{ multiple: false }}
                    value={formik.values.appId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.appId && Boolean(formik.errors.appId)}
                    helperText={formik.touched.appId && formik.errors.appId}
                  >
                    <MenuItem value='' style={{ display: 'none' }}>Please Select</MenuItem>
                    {appArray?.map((option) => (
                      <MenuItem key={option.id + '_appID'} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
            </>}
            <Grid item xs={3} >
              <TextField
                fullWidth
                id={oktaAppID}
                name="searchInput"
                label="Search"
                onChange={adminFind ? BetterFunction : BetterFunctionRoles}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      <IconSearch stroke={1.5} size="1.2rem" />{" "}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {
              adminFind ? 
                  <Grid item xs={3}>
                    <div>
                      {/* <Button
                      variant="outlined"
                      onClick={handleClick}
                      size="large"
                    >
                      More Actions
                    </Button> */}
                    
                    
                      <TextField
                        select
                        fullWidth
                        name='status'
                        label='Status'
                        value={status}
                        onChange={handleClose}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                        <MenuItem value="STAGED">STAGED</MenuItem>
                        <MenuItem value="PROVISIONED">PROVISIONED</MenuItem>
                        <MenuItem value="DEPROVISIONED">DEACTIVATED</MenuItem>
                        <MenuItem value="LOCKED_OUT">LOCKED_OUT</MenuItem>
                        <MenuItem value="SUSPENDED">SUSPENDED</MenuItem>
                        <MenuItem value="RECOVERY">RECOVERY</MenuItem>
                      </TextField>
                      {/* <Menu
                      open={open}
                      anchorEl={LinkElement}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleClose("ALL")}>
                        All
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("ACTIVE")}>
                        Active
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("STAGED")}>
                        Staged
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("PROVISIONED")}>
                        Provisioned
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("DEPROVISIONED")}>
                      Deprovisioned
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("LOCKED_OUT")}>
                        Locked
                      </MenuItem>
                    </Menu> */}
                    </div>
                  </Grid>
                  :null
            }
            {
              oktaAppID || adminFind?
                <Grid item xs={3}>
                    <Button
                    id="demo-positioned-button"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="outlined"
                    onClick={adminFind ? handleExportUsers : handleApplicationExportUsers}
                    size="large"
                    style={{ marginTop: '0.5%', padding:'2.5%' }}
                    
                  >
                    Export to Excel
                  </Button>
              </Grid>
              :
              <></>
            }
           
            {
              adminFind ? 
              <Grid item xs={3} >
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="outlined"
                  onClick={handleClick1}
                  size="large"
                  style={{ marginTop: '0.5%', padding:'2.5%' }}
                  endIcon={<ArrowDropDownIcon />}
                >
                  More Actions
                </Button>
                <Menu
                  id="demo-positioned-button"
                  aria-labelledby="demo-positioned-button"
                  PaperProps={{
                    style: {
                      width: 200,
                      
                    }
                  }}
                  open={open}
                  anchorEl={LinkElement}
                  onClose={() => setLinkElement(null)}
                  sx={{
                    minWidth: '400px'
                  }}
                >
                  <MenuItem onClick={() => handleRoute("activate")} >
                    Activate Users
                  </MenuItem>
                  <MenuItem onClick={() => handleRoute("deactivate")}>
                    Deactivate Users
                  </MenuItem>
                  <MenuItem onClick={() => handleRoute("unlock")}>
                    Unlock Users
                  </MenuItem>
                </Menu>

              </Grid>
              : null
          }
          </Grid>

          <TableContainer component={Paper} >
            <Table
              stickyHeader
              sx={{ minWidth: 650, padding: 0, margin: 0 }}
              aria-label="simple table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell>Sr.No</TableCell> */}
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">First Name</TableCell>
                  <TableCell align="left">Last Name</TableCell>
                  <TableCell align="left">Last Updated</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {FilteredList &&
                  FilteredList.map((item, key) => (
                    <TableRow key={key} style ={ key % 2? { background : "white" }:{ background : "#eaf0ff" }}>
                      {/* <TableCell component="th" scope="row">
                        {key + 1}
                      </TableCell> */}
                      {adminFind ? <TableCell align="left">{item.profile.email}</TableCell> : <TableCell align="left">{item._embedded.user.profile.email}</TableCell>}
                      {adminFind ? <TableCell align="left">{item.profile.firstName}</TableCell> : <TableCell align="left">{item._embedded.user.profile.firstName}</TableCell>}
                      {adminFind ? <TableCell align="left">{item.profile.lastName}</TableCell> : <TableCell align="left">{item._embedded.user.profile.lastName}</TableCell>}
                      <TableCell align="left">{item.lastUpdated}</TableCell>
                      {adminFind ? <TableCell align="left">{item.status==="DEPROVISIONED" ? "DEACTIVATED" : item.status }</TableCell> : <TableCell align="left">{item._embedded.user.status==="DEPROVISIONED" ? "DEACTIVATED" :item._embedded.user.status }</TableCell>}
                      <TableCell align="center" className={classes.ActionItems}>
                        <Tooltip title="Edit User" placement="left-start">
                          <Link to={`/user/edit-user/${item.id}`} state={{applicationId: formik.values.appId}}>
                            <IconButton>
                              <Edit
                                style={{ color: "black" }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        {
                         (adminFind ? item.status === "ACTIVE" : item._embedded.user.status==="ACTIVE") ?
                            <Tooltip title="Suspend User" placement="left-start">
                              <IconButton disabled={disable} onClick={() => UserOperation(item.id, "suspend", item.status)}>
                                <PersonOffIcon />
                              </IconButton>
                            </Tooltip> : null
                        }
                        {
                          (adminFind ? item.status === "SUSPENDED" : item._embedded.user.status==="SUSPENDED")?
                            <Tooltip title="Unsuspend User" placement="left-start">
                              <IconButton disabled={disable} onClick={() => UserOperation(item.id, "unsuspend", item.status)}>
                                <FontAwesomeIcon icon={faUserMinus} style={{ fontSize: '20px' }} />
                              </IconButton>
                            </Tooltip> : null
                        }
                        {
                          (adminFind ? item.status === "LOCKED_OUT" : item._embedded.user.status==="LOCKED_OUT")?
                            <Tooltip title="Unlock User" placement="left-start">
                              <IconButton disabled={disable} onClick={() => UserOperation(item.id, "unlock", item.status)}>
                                <LockOpenIcon style={{ color: "green" }} />
                              </IconButton>
                            </Tooltip> : null
                        }
                        {
                          ((adminFind ? item.status === "STAGED" : item._embedded.user.status==="STAGED") || (adminFind ? item.status === "DEPROVISIONED" : item._embedded.user.status==="DEPROVISIONED"))?
                            <Tooltip title="Activate User" placement="left-start">
                              <IconButton  disabled={disable} onClick={() => UserOperation(item.id, "activate", item.status)}>
                                <FontAwesomeIcon icon={faPersonCircleCheck} style={{ color: '#0731a6' }} />
                              </IconButton>
                            </Tooltip>
                            : null
                        }
                        {
                          (adminFind ? item.status === "PROVISIONED" : item._embedded.user.status==="PROVISIONED")?
                            <Tooltip title="Re-Activate User" placement="left-start">
                              <IconButton disabled={disable} onClick={() =>UserOperation(item.id, "reactivate",item.status)}>
                                <FontAwesomeIcon icon={faPersonCircleCheck} style={{ color: '#0731a6' }} />
                              </IconButton>
                            </Tooltip>
                            : null

                        }
                        {
                          (adminFind ? item.status === "DEPROVISIONED" : item._embedded.user.status==="DEPROVISIONED")?
                            <Tooltip title="Delete User" placement="left-start">
                              <IconButton disabled={disable} onClick={() => DeleteUser(item.id)} >
                                <FontAwesomeIcon icon={faPersonCircleXmark} style={{ color: '#b8170b' }} />
                              </IconButton>
                            </Tooltip> : null
                        }

                        {
                         (adminFind ? (item.status !== "DEPROVISIONED" && item.status !== "RECOVERY"): item._embedded.user.status !== "DEPROVISIONED" && item._embedded.user.status !== "RECOVERY") ?
                            <Tooltip title="Deactivate User" placement="left-start">
                              <IconButton disabled={disable} onClick={() => UserOperation(item.id, "deactivate",item.status)}>
                                <FontAwesomeIcon icon={faPersonCircleMinus} style={{ color: '#807a7d' }} />
                              </IconButton>
                            </Tooltip>
                            : null
                        }
                        {

                        ((adminFind ? item.status === "PROVISIONED" : item._embedded.user.status==="PROVISIONED") || (adminFind ? item.status === "ACTIVE" : item._embedded.user.status==="ACTIVE"))? 
                          <Tooltip title="Reset Password" placement="left-start">
                            <IconButton  disabled={disable} onClick={() => UserOperation(item.id, "reset_password", item.status)}>
                              <FontAwesomeIcon icon={faKey} style={{ color: '#807a7d', fontSize: '20px' }} />
                            </IconButton>
                          </Tooltip>
                        :
                          null
                        }
                        </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {
              ShowMoreButton ?
                <div className={classes.TableButton}>
                  <Button onClick={() => {adminFind ? getUsersAfter() : getUsersAfterRoles()}} variant="text" size="large" style={{ fontSize: '17px', marginTop: '1%' }} >
                    Show More
                  </Button>
                </div>
                : null
            }
          </TableContainer>
        {/* </> : <></>} */}
      </MainCard>
    </>
  );
}

export default SearchUser;
