import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
  Button,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, {  useEffect, useState } from "react";
import { useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import FailureNotification from "ui-component/FailureNotification";
import SuccessNotification from "ui-component/SuccessNotification";
import { IconSearch } from "@tabler/icons";
import Loader from "ui-component/CircularLoader";
import {useFormik} from "formik";
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableRow-root": {
      border: "1px solid #ececec",
    },
    "& .MuiTableCell-body": {
      padding: "5px 10px",
      border: "1px solid #ececec",
    },
    "& .MuiTableCell-head": {
        padding:theme.spacing(1),
        border: '1px solid #ececec',
        fontWeight:1000,
        background: '#4F81BD',
        color: 'white'
    },
  },
  cell: {},
  card: {
    "& .MuiPaper-root": {
      marginTop: theme.spacing(1),
    },
  },
  redIcon: {
    color: "red",
  },
  greenIcon: {
    color: "green",
  },
  blueIcon:{
    color:'#28458e'
},
 greyIcon:{
  color: '#D3D3D3'
 },
  alert: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "1%",
  },
  mainalert: {
    width: "100%",
  },
  TableButton: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function AssignUserToApp() {
  const classes = useStyles();
  const { Id, AppName } = useParams();
  const [filterList, setFilterList] = useState([]);
  const [userFlag, setUserFlag] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [memberCount, setMemberCount] = useState(0);
  const [memberPage, setMemberPage] = useState(0);
  const [memberRowsPerPage, setMemberRowsPerPage] = useState(5);

  const [openError, setopenError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env

  const [ShowMoreButton, setShowMoreButton] = useState(false)
  const [PageAfter, setPageAfter] = useState(null)
  const [Loader1, setLoader] = useState(false)


  const formik = useFormik({
    initialValues:{
      searchInput:""
    },
    validateOnMount:true,
    validationSchema:yup.object({
      searchInput:yup.string().required("Please enter Email id").email("Please enter valid Email id")
    })
  });

  //*******************|| PAGINATION FOR ASSIGNED USERS  ||************************* */

  const handleChangeMemberPage = (event, newPage) => {
    setMemberPage(newPage);
  };

  const handleChangeMemberRowsPerPage = (event) => {
    setMemberRowsPerPage(parseInt(event.target.value, 10));
    setMemberPage(0);
  };

  

//*****************|| GET ALL ASSIGNED USERS API CALL ||********************** */

  useEffect(() => {
    getAssignedUsers();
    //eslint-disable-next-line
  }, []);

  

  //*****************|| GET ALL USERS API  ||********************** */

  // const getUsers = async () => {
  //   setLoader(true)
  //   await axios
  //     .post(`${REACT_APP_API_ENDPOINT}/users/get-users?limit=10`,{
  //        filterType: '1', 
  //         value: "ACTIVE"
  //     })
  //     .then((res) => {
  //       setLoader(false)
  //       // setUserList(res.data.data.body);
  //       setFilterList(res.data.data.body);
  //       setUserFlag(!userFlag);
  //       setShowMoreButton(true)
  //       if (res.data.data.nextLink.length === 1) {
  //         setShowMoreButton(false)
  //       }
  //       const url = res.data.data.nextLink[0].split(';')[0]
  //       let suppressedURL = url.match(/<(.*?)>/)
  //       const after = new URL(suppressedURL[1]).searchParams.get("after")
  //       setPageAfter(after)
  //     })
  //     .catch((err) => {
  //       setLoader(false)
  //       console.log(err);
  //     });
  // };



  //*************** || GET USERS AFTER CLICKING ON SHOW MORE PAGINATION || ****************** */

  // const UserPaginationAfter = async () => {
  //   setLoader(true)
  //   await axios
  //   .post(`${REACT_APP_API_ENDPOINT}/users/get-users?limit=10&after=${PageAfter}`,{
  //      filterType: '1',  value: "ACTIVE"
  //   })
  //   .then((res) => {
  //     setLoader(false)
  //     res.data.data.body.map((item) => {
  //       filterList.push(item)
  //       return item
  //     })
  //     const url = res.data.data.nextLink[0].split(';')[0]
  //     let suppressedURL = url.match(/<(.*?)>/)
  //     const after = new URL(suppressedURL[1]).searchParams.get("after")
  //     setPageAfter(after)
  //     if (res.data.data.nextLink.length === 1) {
  //       setShowMoreButton(false)
  //     }
  //   })
  //   .catch((err) => {
  //     setLoader(false)
  //     console.log(err)
  //   });
  // }
  const UserPaginationAfter = async () => {
    let PageUrl
    if (PageUrl !== null && SearchInputValue !== "") {
      PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${Id}?limit=10&after=${PageAfter}&q=${SearchInputValue}`
    } else if (PageUrl !== null) {
      if (PageAfter !== null) {
        PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${Id}?limit=10&after=${PageAfter}`
      } else {
        PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${Id}?limit=10`
      }
    } else {
      if (PageAfter !== null) {
        PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${Id}?limit=10&after=${PageAfter}`
      } else {
        PageUrl = `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${Id}?limit=10`
      }
    }
    setLoader(true)

    await axios
      .get(PageUrl)
      .then((res) => {
        res.data.data.body.map((item) => {
          filterList.push(item)
          return item
        })
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
        if (res.data.data.nextLink.length === 1) {
          setShowMoreButton(false)
        }
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        console.log(err);
      });
  }
  //*****************|| GET ALL ASSIGNED USERS API  ||********************** */

  const getAssignedUsers = async () => {
    setLoader(true)
    await axios
      .get(
        `${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${Id}`
      )
      .then((res) => {
        setLoader(false)
        const ScopeUser = res.data.data.body.filter( item => item.scope === "USER" )
        setMemberList(ScopeUser)
        setMemberCount(ScopeUser.length);
        setUserFlag(!userFlag)
       // getUsers();
      })
      .catch((err) => {
        setLoader(false)
        setopenError(true);
        setErrorMessage(err.response.data.errorSummary);
      });
  };

  

//*****************|| ASSIGNED USER TO APPLICATION API  ||********************** */

  const onAssignUser = async (userId) => {
    setLoader(true)
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}/apps/assign-user-to-app/${Id}/${userId}`
      )
      .then((res) => {
        setLoader(false)
        setOpenSuccess(true);
        setSuccessMessage("User Assigned To Application");
        getAssignedUsers();
      })
      .catch((error) => {
        setLoader(false)
        setopenError(true);
        setErrorMessage(error.response.data.errorCauses.length>0 ? error.response.data.errorCauses[0].errorSummary : 
          error.response.data.errorSummary );
      });
  };

  //*****************|| REMOVE USER FROM APPLICATION API ||********************** */

  const onRemoveUser = async (userId) => {
    setLoader(true)
    await axios
      .delete(
        `${REACT_APP_API_ENDPOINT}/apps/delete-assigned-users-to-app/${Id}/users/${userId}?sendEmail=true`
      )
      .then((res) => {
        setLoader(false)
        setOpenSuccess(true);
        setSuccessMessage("User Removed from Application");
        getAssignedUsers();
      })
      .catch((error) => {
        setLoader(false)
        setopenError(true);
        setErrorMessage(error.response.data.errorCauses[0].errorSummary);
      });
  };

  //***************** || Alert Handle Close  || ************************* */ 

  const handleCloseAlertError = () => {
    setErrorMessage("");
    setopenError(false);
  };

  const handleCloseAlertSuccess = () => {
    setSuccessMessage("");
    setOpenSuccess(false);
  };

  //Implemenatation of Search
 //eslint-disable-next-line
  const [SearchInputValue, setSearchInputValue] = useState("")
  
  
  // const handleSearchByChange = async (event) => {
  //   setLoader(true)
  //   let { value } = event.target
  //   setSearchInputValue(event.target.value)
  //   let HitUrlApi
  //   if (value !== "") {
  //     HitUrlApi = `${REACT_APP_API_ENDPOINT}/users/get-users?limit=10&searchKeyword=${value}`
  //   }
  //   if(value === "" && adminFind){
  //     HitUrlApi = `${REACT_APP_API_ENDPOINT}/users/`
  //   }
  //   if (value !== "") {
  //     await axios.post(HitUrlApi, {
  //       filterType: '1',
  //       value: "ACTIVE"
  //     }).then(res => {
  //       setLoader(false)
  //       setShowMoreButton(true)
  //       // setStatus("ACTIVE")
  //       setFilterList(res.data.data.body);
  //       if (res.data.data.nextLink.length === 1) {
  //         setShowMoreButton(false)
  //       }
  //       const url = res.data.data.nextLink[0].split(';')[0]
  //       let suppressedURL = url.match(/<(.*?)>/)
  //       const after = new URL(suppressedURL[1]).searchParams.get("after")
  //       setPageAfter(after)
  //     }).catch(err => {
  //       setLoader(false)
  //       console.log(err)
  //     })
  //   } else {
  //     //getUsers()
  //   }
  // } 
  
  const handleSearchByEmail= async(value)=>{
  
    if (value !== "") {
      await axios.post(`${REACT_APP_API_ENDPOINT}/users/get-users-by-email?limit=10&searchKeyword=${value}`, {
        filterType: '1',
        value: "ACTIVE"
      }).then(res => {
        setLoader(false)
        setShowMoreButton(false)
        setFilterList(res.data.data.body);
      }).catch(err => {
        setLoader(false)
      })
    } else {
      setFilterList([])
      //getUsers()
    }
  }

 


  //**************** || DEBOUNCING IMPLEMENTATION FOR SEARCH  || **************** */

  //  const debounce = function (func, delay) {
  //   let timer;
  //   return function () {     //anonymous function
  //     const context = this;
  //     const args = arguments;
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func.apply(context, args)
  //     }, delay);
  //   }
  // }



   //eslint-disable-next-line
   //const BetterFunction = useCallback(debounce(handleSearchByEmail, 500), [])

   useEffect(()=>{
    if(formik.isValid){
      handleSearchByEmail(formik.values.searchInput);
    }
      //eslint-disable-next-line     
   },[formik.values.searchInput])
  //  style={{ margin: '0.5%',position:"absolute", top:'50%', left:'50%', transform:'translate(-50%,-50%)' }}

  return (
    <>
      <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
      <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
      {
        Loader1 ?
          <div style={{ margin: '0.5%',position:"fixed", top:'50%', left:'50%', transform:'translate(-50%,-50%)' }}>
           <Loader />
          </div>
          : null }
      <MainCard title={ `Assigned User To Application : ${AppName}` }>
      
          <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, padding: 0, margin: 0 }}
                aria-label="simple table"
                className={classes.table}
              >
                <TableHead>
                  <TableRow>
                    <TableCell> Sr No. </TableCell>
                    <TableCell align="left">First Name</TableCell>
                    <TableCell align="left">Last Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Created On</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memberList.length > 0
                    ? memberList
                        .slice(
                          memberPage * memberRowsPerPage,
                          memberPage * memberRowsPerPage + memberRowsPerPage
                        )
                        .map((row, id) => (
                          <TableRow key={id} style ={ id % 2 ? { background : "white" }:{ background : "#eaf0ff" }}>
                            <TableCell component="th" scope="row">
                              {memberPage * memberRowsPerPage + id + 1}
                            </TableCell>
                            <TableCell>{row._embedded?.user?.profile?.firstName}</TableCell>
                            <TableCell>{row._embedded?.user?.profile.lastName}</TableCell>
                            <TableCell>{row._embedded?.user?.profile.email}</TableCell>
                            <TableCell>{row._embedded?.user?.status === "DEPROVISIONED" ? "DEACTIVATED" : row._embedded?.user?.status }</TableCell>
                            <TableCell>{row.created}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  onRemoveUser(row.id);
                                }}
                                disabled={Loader1 ? true : false}
                               
                              >
                                <PersonRemoveIcon className={Loader1 ? classes.greyIcon : classes.blueIcon} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                    : null}
                </TableBody>
              </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={memberCount}
            rowsPerPage={memberRowsPerPage}
            page={memberPage}
            onPageChange={handleChangeMemberPage}
            onRowsPerPageChange={handleChangeMemberRowsPerPage}
          />
      </MainCard>
      <MainCard title="All Users" className={classes.card}>
        <Grid container >
            <Grid item xs={4} spacing={4} >
              <TextField
                fullWidth
                id={Id}
                name="searchInput"
                label="Search"
               // onChange={BetterFunction}
                value={formik.values['searchInput'] || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched['searchInput'] && Boolean(formik.errors['searchInput'])}
                helperText={formik.touched['searchInput'] && formik.errors['searchInput']}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      <IconSearch stroke={1.5} size="1.2rem" />{" "}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
        </Grid>
        <TableContainer component={Paper}>
            <Table
              stickyHeader
              sx={{ minWidth: 650, padding: 0, margin: 0 }}
              aria-label="simple table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell> Sr No. </TableCell>
                  <TableCell align="left">First Name</TableCell>
                  <TableCell align="left">Last Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Created On</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterList.map((row, id) => (
                    <TableRow key={id} style ={ id % 2? { background : "white" }:{ background : "#eaf0ff" }}>
                      <TableCell component="th" scope="row">
                        {id + 1}
                      </TableCell>
                      <TableCell>{row.profile.firstName}</TableCell>
                      <TableCell>{row.profile.lastName}</TableCell>
                      <TableCell>{row.profile.email}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.created}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            onAssignUser(row.id);
                          }}
                          disabled={Loader1 ? true : false}
                        >
                          <PersonAddAltIcon className={Loader1 ? classes.greyIcon : classes.blueIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {
              ShowMoreButton ?
                <div className={classes.TableButton}>
                  <Button onClick={() => UserPaginationAfter()} variant="text" size="large" style={{ fontSize: '17px', marginTop: '1%' }} >
                    Show More
                  </Button>
                </div>
                : null
            }
        </TableContainer>
      </MainCard>
    </>
  );
}

export default AssignUserToApp;
