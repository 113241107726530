
import {  Button, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import * as React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { VisibilityOutlined } from '@mui/icons-material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { makeStyles } from '@mui/styles';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router';
import SuccessNotification from "ui-component/SuccessNotification";
import FailureNotification from "ui-component/FailureNotification";
import { Link } from 'react-router-dom';
// import { useConfirm } from 'material-ui-confirm'



const useStyles = makeStyles((theme) => ({
    table: {
        "& .MuiTableRow-root": {
          border: '1px solid #ececec'
        },
        "& .MuiTableCell-body":{
          padding:'5px 10px',
          border: '1px solid #ececec'
        },
        "& .MuiTableCell-head":{
            padding:theme.spacing(2),
            border: '1px solid #ececec'
        }
      },
    card:{
        "& .MuiPaper-root":{
            marginTop:theme.spacing(1)
          },
    },
    TableButton: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center'
      },
    blueIcon:{
        color:'#28458e'
    },
    redIcon:{
        color:'red'
    },
    greenIcon:{
        color:'green'
    },
    ActionItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    }
}));

const SearchGroup = () => {
    const classes = useStyles();
    const { state } = useLocation();
    const [appArray, setAppArray] = React.useState([]);
    const [filterList, setFilterList] =  React.useState([]);
    const [searchBy, setSearchBy] = React.useState(state?.appId ? state?.appId : "");
    const [after,setAfter] = React.useState();
    const [show,setShow] = React.useState(true);
    const authState = useSelector((state)=> state.authUser);
    
    console.log({searchBy})
    // const confirm = useConfirm();

    const [openError, setopenError] = React.useState(state?.isSuccess ? false:null);
    const [ErrorMessage, setErrorMessage] = React.useState(state?.msg?state?.msg:"");
    const [openSuccess, setOpenSuccess] = React.useState(state?.isSuccess ? true:null);
    const [SuccessMessage, setSuccessMessage] = React.useState(state?.msg?state?.msg:"");
    
    const handleCloseAlertError = () => {
        setErrorMessage("");
        setopenError(false);
    };

    const handleCloseAlertSuccess = () => {
        setSuccessMessage("");
        setOpenSuccess(false);
    };
    
  
    const { REACT_APP_API_ENDPOINT } = process.env
    const handleSearchByChange = (event)=>{
        setSearchBy(event.target.value);
    }

    React.useEffect(()=>{
        if(searchBy){
            getAllGroups(searchBy)
        }
        //eslint-disable-next-line
    },[searchBy]);

    React.useEffect(()=>{
        getApps();
        //eslint-disable-next-line
    },[]);

    const getAllGroups =  async (searchBy)=>{
        await axios.get(`${REACT_APP_API_ENDPOINT}/apps/get-groups-by-app/${searchBy}`).then(res=>{
            setFilterList(res?.data.data.body);
            handleNextLink(res?.data?.data?.nextLink);
        },error=>{
            console.log(error);
        })
    }

    const getApps = async () => {
        await axios.post(`${REACT_APP_API_ENDPOINT}/apps/get-apps`,{
            "filterType": 2,
            "value": "ACTIVE"
            }).then((res) => {
                let appOptions = []
                res.data.data?.body.map((item, key) => {
                    if(authState?.roles?.indexOf('partneriam-'+item.id)!==-1){        
                        appOptions.push({
                            'label': item.label,
                            'id': item.id
                        })
                    }
                    return item
                })
                setAppArray(appOptions)
            }
            )
            .catch((err) => console.log(err))
    }

    const handleNextLink = (nextLink)=>{

        if(nextLink.length===1){
            setShow(false);
        }else{
            const url = nextLink[0].split(';')[0]
            let suppressedURL = url.match(/<(.*?)>/)
            const after = new URL(suppressedURL[1]).searchParams.get("after");
            setAfter(after);
            setShow(true);
        }
    }

    const onShowMore = async()=>{
        await axios.get(`${REACT_APP_API_ENDPOINT}/groups/get-groups?limit=10&after=${after}`).then(res=>{
            let array = [];
            res.data.data.body.map(element => {
               array.push(element);
                return element;
            });
            setFilterList([...filterList,...array]);
            handleNextLink(res.data.data.nextLink);
        }).catch(error=>{
            console.log(error)
        })
    }


    // const handleDeleteGroup = async(groupId,groupName)=>{
    //     confirm({ description: `This will permanently Delete group ${groupName}` })
    //     .then(async () => {
    //         await axios.delete(`${REACT_APP_API_ENDPOINT}/groups/delete-group/${groupId}`).then(res=>{
    //             getAllGroups();
    //             setOpenSuccess(true);
    //             setSuccessMessage('Group Deleted Successfully');
    //         },err=>{
    //             setErrorMessage(false)
    //             setSuccessMessage('Group Deletion Failed');
    //         })
    //     });

    // }

    return (
        <>
        <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
        <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
        <MainCard title="Search Group" className={classes.card} id="Main">
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        select
                        fullWidth
                        value={searchBy}
                        id="appId"
                        name="appId"
                        label="Application"
                        onChange={handleSearchByChange}
                    >
                      {appArray?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.label}
                                </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 650, padding: 0, margin: 0 }} aria-label="simple table" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Sr No.</TableCell> */}
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">Created on</TableCell>
                            <TableCell align="center">Updated on</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        filterList && filterList.length>0?
                        filterList
                        .map((row, id) => (
                            <TableRow
                                key={id}
                            >
                                {/* <TableCell component="th" scope="row">
                                    {id+1}
                                </TableCell> */}
                                <TableCell align="center">{row._embedded.group.profile.name}</TableCell>
                                <TableCell align="center">{row._embedded.group.profile.description}</TableCell>
                                <TableCell align="center">{row._embedded.group.created}</TableCell>
                                <TableCell align="center">{row._embedded.group.lastUpdated}</TableCell>
                                <TableCell align="center" className={classes.ActionItem}>
                                    <Link to={`/groups/edit-group/${row.id}`} state={{appId : searchBy}}>
                                    <IconButton>
                                        <VisibilityOutlined className={classes.blueIcon}/>
                                    </IconButton>
                                    </Link>
                                    <Link  to={`/groups/assign-group-user/${row.id}`}>
                                    <IconButton>
                                        <PersonAddAltIcon  className={classes.greenIcon} />
                                    </IconButton>
                                    </Link>
                                    {/* <Link to='#' onClick={()=>handleDeleteGroup(row.id,row._embedded.group.profile.name)}>
                                    <IconButton>
                                        <RemoveCircleOutlineTwoTone className={classes.redIcon}/>
                                    </IconButton>
                                    </Link> */}
                                   
                                </TableCell>
                            </TableRow>
                        ))
                        :
                        <>
                        <TableRow>
                            <TableCell colSpan={5} align="center">No Records Found</TableCell>
                        </TableRow>
                        </>
                    }
                    </TableBody>
                </Table>
                {
                   filterList && filterList.length>0 && show?

                  <div className={classes.TableButton}>
                    <Button onClick={onShowMore}  variant="text" size="large" style={{ fontSize: '17px', marginTop: '1%' }} >
                      Show More
                    </Button>
                  </div>
                  :
                  <></>
                }
            </TableContainer>
        </MainCard>
        </>
    )
}

export default SearchGroup;