import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TablePagination,
    Button,
  } from "@mui/material";
  import React, {  useEffect, useState } from "react";
  import { useParams } from "react-router";
  import MainCard from "ui-component/cards/MainCard";
  import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
  import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
  import axios from "axios";
  import { makeStyles } from "@mui/styles";
import FailureNotification from "ui-component/FailureNotification";
import SuccessNotification from "ui-component/SuccessNotification";
  
  const useStyles = makeStyles((theme) => ({
    table: {
      "& .MuiTableRow-root": {
        border: "1px solid #ececec",
      },
      "& .MuiTableCell-body": {
        padding: "5px 10px",
        border: "1px solid #ececec",
      },
      "& .MuiTableCell-head": {
        padding: theme.spacing(2),
        border: "1px solid #ececec",
      },
    },
    cell: {},
    card: {
      "& .MuiPaper-root": {
        marginTop: theme.spacing(1),
      },
    },
    redIcon: {
      color: "red",
    },
    greenIcon: {
      color: "green",
    },
    blueIcon:{
      color:'#28458e'
  },
    alert: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      margin: "1%",
    },
    mainalert: {
      width: "100%",
    },
    TableButton: {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center'
    },
  }));

  
function AssignGroupToApp() {
    const classes = useStyles();
    const { Id, AppName } = useParams();
    const [filterList, setFilterList] = useState([]);
    // const [userPage, setUserPage] = useState(0);
    // const [userRowsPerPage, setUserRowsPerPage] = useState(5);
    // const [userCount, setUserCount] = useState(0);
    const [userFlag, setUserFlag] = useState(false);
    const [userList, setUserList] = useState([]);
    const [memberIdArray, setMemberIdArray] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [memberCount, setMemberCount] = useState(0);
    const [memberPage, setMemberPage] = useState(0);
    const [memberRowsPerPage, setMemberRowsPerPage] = useState(5);

    const [openError, setopenError] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [SuccessMessage, setSuccessMessage] = useState("");
    const { REACT_APP_API_ENDPOINT } = process.env
    const [after,setAfter] = useState();
    const [show,setShow] = useState(true);
  
    //*******************|| PAGINATION FOR ASSIGNED GROUPS  ||************************* */
  
    const handleChangeMemberPage = (event, newPage) => {
      setMemberPage(newPage);
    };
  
    const handleChangeMemberRowsPerPage = (event) => {
      setMemberRowsPerPage(parseInt(event.target.value, 10));
      setMemberPage(0);
    };
  
    
  
  //*****************|| GET ALL ASSIGNED GROUPS API CALL ||********************** */
  
    useEffect(() => {
         getAssignedGroups();
      //eslint-disable-next-line
    }, []);

  
  
    //*****************|| FILTER GROUPS  ||********************** */
  
    useEffect(() => {
      const userFilterList = userList.filter((item) => {
        return !memberIdArray.includes(item.id);
      });
      setUserList(userFilterList);
      setFilterList(userFilterList);
      // setUserCount(userFilterList.length);
      //eslint-disable-next-line
    }, [userFlag]);
  
  
    //*****************|| GET ALL GROUPD API  ||********************** */
  
    const getGroups = async () => {
      await axios
        .get(`${REACT_APP_API_ENDPOINT}/groups/get-groups`)
        .then((res) => {
          setUserList(res.data.data.body);
          setFilterList(res.data.data.body);
          // setUserCount(res.data.data.length);
          setUserFlag(!userFlag);
          handleNextLink(res.data.data.nextLink);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    //*****************|| GET ALL ASSIGNED GROUPS API  ||********************** */
  
    const getAssignedGroups = async () => {
      await axios
        .get(
          `${REACT_APP_API_ENDPOINT}/apps/get-assigned-group-of-app/${Id}`
        )
        .then((res) => {
          let array = [];
          res.data.data.map((item) => {
            array.push(item.id);
            return item;
          });
          setMemberIdArray(array);
          setMemberCount(res.data.data.length);
          setMemberList(res.data.data)
          getGroups();
        })
        .catch((err) => {
          setopenError(true);
          setErrorMessage(err.response.data?.errorSummary);
        });
    };

  
  
  
  //*****************|| ASSIGN GROUP TO APPLICATION API  ||********************** */
  
    const onAssignGroup = async (GroupId) => {
      await axios
        .post(
          `${REACT_APP_API_ENDPOINT}/apps/assign-group-to-app/${Id}/${GroupId}`
        )
        .then((res) => {
          setOpenSuccess(true);
          setSuccessMessage("Group Assigned To Application");
          getAssignedGroups();
        })
        .catch((error) => {
          setopenError(true);
          setErrorMessage(error.response.data.errorSummary);
        });
    };
  
    //*****************|| REMOVE GROUP FROM APPLICATION API ||********************** */
  
    const onRemoveGroup = async (GroupId) => {
      await axios
        .delete(`${REACT_APP_API_ENDPOINT}/apps/delete-assigned-group-to-app/${Id}/group/${GroupId}`)
        .then((res) => {
          setOpenSuccess(true);
          setSuccessMessage("Group Removed from Application");
          getAssignedGroups();
        })
        .catch((error) => {
          setopenError(true);
          setErrorMessage(error.response.data.errorSummary);
        });
    };

    //***************|| HANDLING SHOW MORE || *********************** */

  const handleNextLink = (nextLink)=>{
    if(nextLink.length===1){
        setShow(false);
    }else{
        const url = nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after");
        setAfter(after);
        setShow(true);
    }
}

const onShowMore = async()=>{
  await axios.get(`${REACT_APP_API_ENDPOINT}/groups/get-groups?limit=5&after=${after}`).then(res=>{
      let array = [];
      res.data.data.body.map(element => {
         array.push(element);
          return element;
      });
      setFilterList([...filterList,...array]);
      handleNextLink(res.data.data.nextLink);
  }).catch(error=>{
      console.log(error)
  })
}
  
    //***************** || Alert Handle Close  || ************************* */ 
  
    const handleCloseAlertError = () => {
      setErrorMessage("");
      setopenError(false);
    };
  
    const handleCloseAlertSuccess = () => {
      setSuccessMessage("");
      setOpenSuccess(false);
    };
  
  
  return (
    <>
      <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
      <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
      
      <MainCard title={`Application Name : ${AppName}  `}>
        <h3>Assigned Groups To Application</h3>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, padding: 0, margin: 0 }}
            aria-label="simple table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell> Sr No. </TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Created On</TableCell>
                <TableCell align="left">Updated On</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {memberList.length > 0
                ? memberList
                    .slice(
                      memberPage * memberRowsPerPage,
                      memberPage * memberRowsPerPage + memberRowsPerPage
                    )
                    .map((row, id) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          {memberPage * memberRowsPerPage + id + 1}
                        </TableCell>
                        <TableCell align="left">{row._embedded?.group?.profile?.name}</TableCell>
                        <TableCell align="left">{row._embedded?.group?.profile?.description}</TableCell>
                        <TableCell align="left">{row.created}</TableCell>
                        <TableCell align="left">{row.lastUpdated}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                                onRemoveGroup(row.id);
                            }}
                          >
                            <PersonRemoveIcon className={classes.blueIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={memberCount}
          rowsPerPage={memberRowsPerPage}
          page={memberPage}
          onPageChange={handleChangeMemberPage}
          onRowsPerPageChange={handleChangeMemberRowsPerPage}
        />
        <h3>All Groups</h3>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, padding: 0, margin: 0 }}
            aria-label="simple table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell> Sr No. </TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Created On</TableCell>
                <TableCell align="left">Updated On</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterList
                .map((row, id) => (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      {id + 1}
                    </TableCell>
                    <TableCell align="left">{row.profile.name}</TableCell>
                    <TableCell align="left">{row.profile.description}</TableCell>
                    <TableCell align="left">{row.created}</TableCell>
                    <TableCell align="left">{row.lastUpdated}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                            onAssignGroup(row.id);
                        }}
                      >
                        <PersonAddAltIcon className={classes.blueIcon} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {
            show?
                  <div className={classes.TableButton}>
                    <Button onClick={onShowMore}  variant="text" size="large" style={{ fontSize: '17px', marginTop: '1%' }} >
                      Show More
                    </Button>
                  </div>
                  :
                  <></>
        }
      </MainCard>
    </>
  )
}

export default AssignGroupToApp