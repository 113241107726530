import { IconUser, IconUserPlus,IconUserSearch } from '@tabler/icons';
import { IconUpload, IconDownload } from '@tabler/icons';

const icons = { IconUser, IconUserPlus, IconUserSearch, IconUpload, IconDownload };


const user = {
    id: 'user',
    type: 'user',
    children: [
        {
            id: 'user-collapse',
            title: 'User Management',
            type: 'collapse',
            icon: icons.IconUser,
            children: [
                {
                    id: 'create-user',
                    title: 'Create User',
                    type: 'item',
                    isAdmin:0,
                    url: '/user/create-user',
                    icon: icons.IconUserPlus,
                    breadcrumbs: false
                },
                // {
                //     id:'download-sample-file',
                //     title:'Download Sample File',
                //     type:'item',
                //     isAdmin:0,
                //     url:'/user/download-sample-file',
                //     icon:icons.IconDownload,
                //     breadcrumbs:true
                // },
                {
                    id: 'import-user',
                    title: 'Import User',
                    type: 'item',
                    isAdmin:0,
                    url: '/user/import-user',
                    icon: icons.IconUpload,
                    breadcrumbs: false
                },
                // {
                //     id: 'import-modify-user',
                //     title: 'Import Modify User',
                //     type: 'item',
                //     isAdmin:0,
                //     url: '/user/import-modify-user',
                //     icon: icons.IconUpload,
                //     breadcrumbs: false
                // },
                {
                    id: 'search-user',
                    title: 'List Users',
                    type: 'item',
                    isAdmin:1,
                    url: '/user/list-users',
                    icon: icons.IconUserSearch,
                    breadcrumbs: false
                },
                {
                    id: 'admin-search-user',
                    title: 'List Users',
                    type: 'item',
                    isAdmin:2,
                    url: '/admin/list-users',
                    icon: icons.IconUserSearch,
                    breadcrumbs: false
                }
            ]
        }
    ]
}

export default user;