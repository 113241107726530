import { Alert, AlertTitle, Slide, Snackbar } from "@mui/material"
const SuccessNotification = ({openSuccess, handleAlertSuccess, SuccessMessage}) => {
    return(
      <Snackbar
        open={openSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleAlertSuccess}
        TransitionComponent={Slide}
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionProps={{ enter: true, exit: false }}
        sx={{
          color: "secondary",
          "& .MuiSnackbarContent-root": { backgroundColor: "green" }
        }}
      >
        <Alert severity="success" variant="filled" onClose={handleAlertSuccess}>
            <AlertTitle>Success</AlertTitle>
            <strong>{SuccessMessage}</strong>
        </Alert>
      </Snackbar>  
    )
}

export default SuccessNotification