import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
  Switch
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import { AddCircleOutlineSharp, VisibilityOutlined } from "@mui/icons-material";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { makeStyles } from "@mui/styles";
// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { IconSearch } from "@tabler/icons";
// import { useConfirm } from 'material-ui-confirm'
import { Link } from 'react-router-dom'
import { useSelector } from "react-redux";
import Loader from "ui-component/CircularLoader";
import FailureNotification from "ui-component/FailureNotification";
import SuccessNotification from "ui-component/SuccessNotification";

const useStyles = makeStyles((theme) => ({
  root: {},
  ActionItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  mainalert: {
    width: "100%",
  },
  TableButton: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
  },

  table: {
    "& .MuiTableRow-root": {
      border: '1px solid #ececec'
    },
    "& .MuiTableCell-body":{
      padding:'5px 10px',
      border: '1px solid #ececec'
    },
    "& .MuiTableCell-head":{
        padding:theme.spacing(1),
        border: '1px solid #ececec',
        fontWeight:1000,
        background: '#4F81BD',
        color: 'white'
    }
  },
  blueIcon:{
    color:'#050558'
},
}));

function SearchApp() {

  const state = useSelector(state => state);
  const roles = state.authUser.roles;

  

  let roleID = roles.map((role) => role.split("partneriam-")[1]);
  let adminFind = roleID.includes("admin");
  
  

  const classes = useStyles();
  const { REACT_APP_API_ENDPOINT, REACT_APP_OKTA_DISABLE_DEFAULT_APPS} = process.env


  //***************** || AppData  Initialize || ************************* */

  // const [AppData, setAppData] = useState([]);
  const [FilteredList, setFilterList] = useState([]);

  //***************** || Pagination  Initialize || ************************* */
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [count, setCount] = useState(0);
  const [searchBy, setSearchBy] = useState("");

  const [after,setAfter] = React.useState();
  const [show,setShow] = React.useState(true);

  //***************** || Alert Initialize || ************************* */ 

  // const confirm = useConfirm()
  //eslint-disable-next-line
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [openError, setopenError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);

  const [Loader1, setLoader] = useState(false)


  //***************** || APPLICATION DATA || ************************* */

  const GetApplication = async () => {
    setLoader(true)
    await axios
      .post(`${REACT_APP_API_ENDPOINT}/apps/get-apps?limit=${adminFind ? 10 : 200}`)
      .then((res) => {
        let newFilterList = []
        let AdminList = []
        let DefaultOktaApps=REACT_APP_OKTA_DISABLE_DEFAULT_APPS.split(",")
        res.data.data.body
        .map((item => {
          if(adminFind && !DefaultOktaApps.includes(item.id)){
            item = changeModeValue(item)
            AdminList.push(item)
          }else{
            item = changeModeValue(item);
            roleID.map((value => {
              if (item.id === value) {
                newFilterList.push(item)
              }
              return value;
            }))
          }
          return item;
        }))
        setLoader(false)
        handleNextLink(res.data.data.nextLink);
        adminFind ? setFilterList(AdminList) : setFilterList(newFilterList)
        // adminFind ? setCount(res.data.data.length) : setCount(newFilterList)
      }).catch(err => {
        setLoader(false)
        setopenError(true)
        setErrorMessage(err)
      });
  };

  const changeModeValue =  (item)=>{
    if(item.signOnMode==="AUTO_LOGIN"){
      item.signOnMode = "Auto Login"
      return item;
    }else if(item.signOnMode==="BASIC_AUTH"){
      item.signOnMode = "Basic Auth"
      return item;
    }else if(item.signOnMode==="BOOKMARK"){
      item.signOnMode = "Bookmark"
      return item;
    }else if(item.signOnMode==="BROWSER_PLUGIN"){
      item.signOnMode = "Browser Plugin"
      return item;
    }else if(item.signOnMode==="Custom"){
      item.signOnMode = "Custom"
      return item;
    }else if(item.signOnMode==="OPENID_CONNECT"){
      item.signOnMode = "Open ID Connect"
      return item;
    }else if(item.signOnMode==="SAML_1_1"){
      item.signOnMode = "SAML 1.O"
      return item;
    }else if(item.signOnMode==="SAML_2_0"){
      item.signOnMode = "SAML 2.O"
      return item;
    }else if(item.signOnMode==="SECURE_PASSWORD_STORE"){
      item.signOnMode = "Secure Web Authentication"
      return item;
    }
    else if(item.signOnMode==="WS_FEDERATION"){
      item.signOnMode = "WS-Federation"
      return item;
    }
    return item;
  }

  useEffect(() => {
    GetApplication();
    //eslint-disable-next-line
  }, []);

  //************** || PAGINATION CHANGE || ***************************** */

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleSearchByChange = (event) => {
  //   setSearchBy(event.target.value);
  // };


  //***********************|| SEARCH IMPLEMENTATION || ******************** */

  // useEffect(() => {
  //   let filteredArray = AppData.filter((item) =>
  //     item.label.toLowerCase().startsWith(searchBy.toLowerCase())
  //   );
  //   setFilterList(filteredArray);
  //   // setCount(filteredArray.length);
  //   //eslint-disable-next-line
  // }, [searchBy]);

  const ApplicationSearch = async (event) => {
    setLoader(true)
    let { value } = event.target
    setSearchBy(value)
    await axios.post(`${REACT_APP_API_ENDPOINT}/apps/get-apps?q=${value}&limit=10`)
    .then( res => {
        // console.log({res})
        let newFilterList1 = []
        let AdminList = []
        let DefaultOktaApps=REACT_APP_OKTA_DISABLE_DEFAULT_APPS.split(",")
        res.data.data.body.map((item => {
          if(adminFind && !DefaultOktaApps.includes(item.id)){
            item = changeModeValue(item)
            AdminList.push(item)
          }else{
            item = changeModeValue(item)
            roleID.map((value => {
              if (item.id === value) {
                newFilterList1.push(item)
              }
              return value;
            }))
          }
          return item;
        }))
        if(adminFind) {
          handleNextLink(res.data.data.nextLink)
        }
        adminFind ? setFilterList(AdminList) : setFilterList(newFilterList1)
        setLoader(false)
    }).catch(err => {
      setLoader(false)
      setopenError(true)
      setErrorMessage(err)
    })
  }

  //**************** || DEBOUNCING IMPLEMENTATION FOR SEARCH  || **************** */

  const debounce = function (func, delay) {
    let timer;
    return function () {     //anonymous function
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args)
      }, delay);
    }
  }

  //eslint-disable-next-line
  const BetterFunction = useCallback(debounce(ApplicationSearch, 500), [])

  

  //***************|| HANDLING SHOW MORE || *********************** */

  const handleNextLink = (nextLink)=>{
    // console.log(nextLink.length)
    if(nextLink.length===1){
        setShow(false);
    }else{
        const url = nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after");
        setAfter(after);
        setShow(true);
    }
}

const onShowMore = async()=>{
  setLoader(true)
  let GeneralAfterGetAppsUrl
  if(searchBy.length >0){
     GeneralAfterGetAppsUrl = `${REACT_APP_API_ENDPOINT}/apps/get-apps?limit=10&after=${after}&q=${searchBy}`
  }else{
     GeneralAfterGetAppsUrl = `${REACT_APP_API_ENDPOINT}/apps/get-apps?limit=10&after=${after}`
  }
    await axios.post(GeneralAfterGetAppsUrl).then(res=>{
        let array = [];
        res.data.data.body.map(element => {
          element = changeModeValue(element);
           array.push(element);
            return element;
        });
        setFilterList([...FilteredList,...array]);
        handleNextLink(res.data.data.nextLink);
        setLoader(false)
    }).catch(error=>{
        setLoader(false)
        setopenError(true)
        setErrorMessage(error)
    })
}



  //*************** || DELETE APPLICATION || **************** */

  // const handleDelete = (item) => {
  //   confirm({ description: `This will permanently Delete Application ${item.label}` })
  //     .then(async () => {
  //       await axios.delete(`${REACT_APP_API_ENDPOINT}/apps/delete-app/${item.id}`)
  //         .then(res => {
  //           setOpenSuccess(true)
  //           setSuccessMessage(`${item.label} Application Deleted`)
  //           GetApplication();
  //         }).catch(err => {
  //           console.log(err)
  //         })
  //     })
  //     .catch(() => console.log("Deletion cancelled."));
  // }


  //***************** || Alert Handle Close  || ************************* */ 


    const handleCloseAlertError = () => {
      setErrorMessage("");
      setopenError(false);
    };
  
    const handleCloseAlertSuccess = () => {
      setSuccessMessage("");
      setOpenSuccess(false);
    };

  //******************* || Toggle On/Off || ****************/

  
  const handleToggle = async (e,item,key) => {
    setLoader(true)
   await axios.post(`${REACT_APP_API_ENDPOINT}/apps/update-app-status-for-myidm-form`,{
    appId: item.id,
    appName: item.label,
    enabled: e.target.checked
   }).then( res => {
    if(res.data.responseStatus === "SUCCESS"){
      let items = [...FilteredList];
      // 2. Make a shallow copy of the item you want to mutate
      let item = {...items[key]};
      // 3. Replace the property you're intested in
      item.enabled = !e.target.checked;
      // 4. Put it back into our array. N.B. we *are* mutating the array here, 
      //    but that's why we made a copy first
      items[key] = item;
      setFilterList(items)
    }
      setLoader(false)
   }).catch(err => {
     setLoader(false)
     setopenError(true)
     setErrorMessage(err)
   })
  //   if (e.target.checked) {
  //     const checkArray = FilteredList.map((app) => {
  //       if (app.key === e.target.value) {
  //         const temp = {
  //           ...app,
  //           isSelect: true,
  //         };
  //         return temp;
  //       } else {
  //         return app;
  //       }
  //     });
  //     setFilterList(checkArray);
  //   } else {
  //     const uncheckArray = FilteredList.map((app) => {
  //       if (app.key === e.target.value) {
  //         const temp = {
  //           ...app,
  //           isSelect: false,
  //         };
  //         return temp;
  //       } else {
  //         return app;
  //       }
  //     });
  //     setFilterList(uncheckArray)
  // }
}
  
  return (
    <>
    <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
      <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
      {
        Loader1 ?
          <div style={{ margin: '0.5%',position:"fixed", top:'50%', left:'50%', transform:'translate(-50%,-50%)' }}>
           <Loader />
          </div>
          : null }
    <MainCard title="Search Application" id="Main">
      <Grid container justifyContent="flex-start" marginBottom={"10px"}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="searchBy"
            name="searchBy"
            label="Search By Application Name"
            onChange={BetterFunction}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  {" "}
                  <IconSearch stroke={1.5} size="1.2rem" />{" "}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          stickyHeader
      
          aria-label="simple table"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              {/* <TableCell>Sr.No</TableCell> */}
              <TableCell align="left">Application Name</TableCell>
              <TableCell align="left">Application Type</TableCell>
              <TableCell align="left">Created On</TableCell>
              <TableCell align="left">Updated On</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="center">Action Items</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {FilteredList &&
              FilteredList.map((item, key) => (
                <TableRow key={key} style ={ key % 2? { background : "white" }:{ background : "#eaf0ff" }}>
                  {/* <TableCell component="th" scope="row">
                    {key + 1}
                  </TableCell> */}
                  <TableCell align="left">{item.label}</TableCell>
                  <TableCell align="left" className={classes.TableCell}>{item.signOnMode}</TableCell>
                  <TableCell align="left">{item.created}</TableCell>
                  <TableCell align="left">{item.lastUpdated}</TableCell>
                  <TableCell align="left">{item.status}</TableCell>
                  <TableCell align="center" className={classes.ActionItems}>
                    <Link to={`/application/edit-application/${item.id}`}>
                      <Tooltip title="View Application" placement="left-start">
                        <IconButton>
                          <VisibilityOutlined
                             className={classes.blueIcon}
                          />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Link to={`/user/create-user`} state={{applicationId:item.id,applicationName:item.label}}>
                      <Tooltip title="Create User" placement="left-start">
                        <IconButton>
                          <PersonAddAltIcon
                             className={classes.blueIcon}
                          />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Link to={`/application/assign-user-application/${item.label}/${item.id}`} >
                      
                      <Tooltip
                        title="Assign User to Application"
                        placement="left-start"
                      >
                        <IconButton>
                          <AddCircleOutlineSharp
                            className={classes.blueIcon}
                          />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    {
                        adminFind &&
                        <Tooltip
                            title="MyIdm Form"
                            placement="left-start"
                        >
                          <Switch value={item?.key} checked={item?.enabled} onChange={(e) => handleToggle(e,item, key)}/>
                        </Tooltip>
                    }
                    
                    {/* <Link to={`/application/assign-group-application/${item.label}/${item.id}`} >
                      <Tooltip
                        title="Assign Group to Application"
                        placement="left-start"
                      >
                        <IconButton>
                          <GroupAddIcon
                           className={classes.blueIcon}
                          />
                        </IconButton>
                      </Tooltip>
                    </Link> */}
                    {/* <Tooltip title="Delete Application" placement="left-start">
                      <IconButton
                        onClick={() => handleDelete(item)}
                      >
                        <DeleteOutlinedIcon
                          style={{ color: "red", marginBottom: "9px" }}
                        />
                      </IconButton>
                    </Tooltip> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {
            show && FilteredList.length>0?
                  <div className={classes.TableButton}>
                    <Button onClick={onShowMore}  variant="text" size="large"  className={classes.blueIcon} >
                      Show More
                    </Button>
                  </div>
                  :
                  <></>
        }
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </MainCard>
    </>
  );
}

export default SearchApp;
