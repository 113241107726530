import * as MenuActions from '../actions/MenuActions'


export const initState = {
    menu:""
}
const menuReducer  = (state=initState,action)=>{ 
    switch(action.type){
        case MenuActions.OPEN_MENU:
            return {
                ...state,
                menu:action.payload
            }
        case MenuActions.CLOSE_MENU:
            return {
                ...state,
                menu:""
            }
        default:
            return {
                ...state
            }
    }
}
export default menuReducer;