// assets
import {  IconApps, IconCirclePlus, IconListSearch } from '@tabler/icons';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

// constant
const icons = {
    IconCirclePlus,
    IconApps,
    GroupAddOutlinedIcon,
    IconListSearch
    
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: '',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'app-collapse',
            title: 'Application',
            type: 'collapse',
            icon: icons.IconApps,

            children: [
                // {
                //     id: 'CreateApp',
                //     title: 'Create-Application',
                //     type: 'item',
                //     url: '/application/create-application/oidc',
                //     icon: icons.IconCirclePlus
                    
                // },
                {
                    id: "admin-search-app",
                    title: 'Search-App',
                    type: 'item',
                    isAdmin:1,
                    url: '/application/search-application',
                    icon: icons.IconListSearch
                },
                {
                    id: "search-app",
                    title: 'Search-App',
                    type: 'item',
                    isAdmin:2,
                    url: '/admin/search-application',
                    icon: icons.IconListSearch
                }
            ]
        }
    ]
};

export default pages;
