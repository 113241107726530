import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

function Logout() {
    const {oktaAuth} = useOktaAuth()
    const dispatch = useDispatch()
    useEffect(  () => {
      async function logOut () {
        localStorage.clear()
        await oktaAuth.signOut()
        dispatch({type: 'USER_LOGOUT'})
      }
       logOut() 
        //eslint-disable-next-line
    },[])
  return (
    <>
    </>
  )
}

export default Logout