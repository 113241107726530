import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Autocomplete, Button, Checkbox, Grid, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import FailureNotification from 'ui-component/FailureNotification';
import SuccessNotification from 'ui-component/SuccessNotification';

const DownloadSampleFile = () => {
  const [appArray, setAppArray] = React.useState([]);
  const [baseProps, setBaseProps] = React.useState(null);
  const [customProps, setCustomProps] = React.useState(null);

//********* || ALERTS  || ****************/

const [openError, setopenError] = React.useState(false);
const [ErrorMessage, setErrorMessage] = React.useState("");
const [openSuccess, setOpenSuccess] = React.useState(false);
const [SuccessMessage, setSuccessMessage] = React.useState("");



  const { REACT_APP_API_ENDPOINT, REACT_APP_OKTA_DISABLE_DEFAULT_APPS } =
    process.env;
  const DisableDefaultOktaApps = REACT_APP_OKTA_DISABLE_DEFAULT_APPS.split(',');

  const state = useSelector((state) => state);
  const roles = state.authUser.roles;

  let roleID = roles.map((role) => role.split('partneriam-')[1]);
  let adminFind = roleID.includes('admin');

  //***************** || Alert Handle Close  || ************************* */ 

  const handleCloseAlertError = () => {
    setErrorMessage("");
    setopenError(false);
};

const handleCloseAlertSuccess = () => {
    setSuccessMessage("");
    setOpenSuccess(false);
};

  const getApps = async (searchValue) => {
    await axios
      .post(`${REACT_APP_API_ENDPOINT}/apps/get-apps?q=${searchValue}&limit=200`, {
        filterType: 2,
        value: 'ACTIVE',
      })
      .then((res) => {
        let appOptions = [];
        let appOptionsRoles = [];
        res.data.data?.body?.map((item, key) => {
          if (!DisableDefaultOktaApps.includes(item.id)) {
            appOptions.push({
              label: item.label,
              id: item.id,
            });
          }
          return item;
        });
        if (adminFind) {
          setAppArray(appOptions);
        } else {
          roleID.map((value) => {
            appOptions.map((item) => {
              if (item.id === value) {
                appOptionsRoles.push({
                  label: item.label,
                  id: item.id,
                });
              }
              return item;
            });
            return value;
          });
          setAppArray(appOptionsRoles);
        }
      })
      .catch((err) => {
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      });
  };

  React.useEffect(() => {
    getApps("");
    //eslint-disable-next-line
  }, []);

  let validationObject = {
    appID: yup.string().required('Application Name is required'),
    isSendEmail: yup
    .string()
  };
  let initialValues = {
    appID: '',
    attributes: [],
    isSendEmail: "true",
  };
  const validationSchema = yup.object(validationObject);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      downloadSampleFile({
        applicationId: values.appID,
        attributes: values.attributes,
        isSendEmail: values.isSendEmail
      });
    },
  });

  const downloadSampleFile = async (value) => {
    await axios
      .post(`${REACT_APP_API_ENDPOINT}/users/download-sample-file`, value, {
        responseType: 'blob',
      })
      .then((response) => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], {
          type: type,
          encoding: 'UTF-8',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'sample_file.xlsx';
        link.click();
      })
      .catch((err) => {
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      });
  };

  const getDefaultUserSchema = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}/apps/get-default-user-schema`)
      .then((res) => {
        setBaseProps(res.data.definitions.base.properties);
        setCustomProps(res.data.definitions.custom.properties);
        let array = [];
        Object.entries(res.data.definitions.base.properties).map(
          ([key, { required }], i) => {
            if (required) {
              array.push(key);
            }
            return key;
          }
        );
        Object.entries(res.data.definitions.custom.properties).map(
          ([key, { required }], i) => {
            if (required) {
              array.push(key);
            }
            return key;
          }
        );

        formik.setFieldValue('attributes', array);
      })
      .catch((err) => {
        if(err.response.status === 429){
          setopenError(true);
          setErrorMessage('Too Many Requests')
      }else{
          console.log(err)
      }
      });
  };

  React.useEffect(() => {
    getDefaultUserSchema();
    //eslint-disable-next-line
  }, []);

  // const getUserSchemaByApp = async () => {
  //   await axios
  //     .get(
  //       `${REACT_APP_API_ENDPOINT}/apps/get-user-schema-by-app/${formik.values.appID}`
  //     )
  //     .then((res) => {
  //       setCustomProps(res.data.definitions.custom.properties);
  //       let array = [...formik.values.attributes];
  //       Object.entries(res.data.definitions.custom.properties).map(
  //         ([key, { required }], i) => {
  //           if (required) {
  //             array.push(key);
  //           }
  //           return key;
  //         }
  //       );
  //       formik.setFieldValue('attributes', array);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // React.useEffect(() => {
  //   if (formik.values.appID) {
  //    // getUserSchemaByApp();
  //   }
  //   //eslint-disable-next-line
  // }, [formik.values.appID]);

  return (
    <>
    <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
    <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
    <MainCard title="Download Sample File" id="Main">
      <Box
        component="form"
        sx={{
          '&.MuiTextField-root': { m: 0 },
          flexGrow: 1,
        }}
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
          <Autocomplete
              fullWidth
              options={appArray}
              name='appID'    
              onChange={ (e , value) => {
                  formik.setFieldValue("appID", value?.id)
              }}
              onBlur={() => {
                formik.setFieldTouched('appID')
              }}
              renderInput={ (params) => (
                <TextField 
                  {...params}
                    label="Application Name*"
                    onChange={ (e) => {
                      getApps(e.target.value)
                    }}
                    error={formik.touched.appID && Boolean(formik.errors.appID)}
                    helperText={formik.touched.appID && formik.errors.appID}
                />
                )}
           />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              name="isSendEmail"
              fullWidth
              label="Do you want to send activation email?"
              value={formik.values.isSendEmail || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.isSendEmail && Boolean(formik.errors.isSendEmail)
              }
              helperText={formik.touched.isSendEmail && formik.errors.isSendEmail}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
          </Grid>

          {baseProps && formik.values.appID ? (
            <>
              <Grid item xs={12}>
                <h4>Base Attributes</h4>
                <Grid container>
                  {baseProps && formik.values.appID ? (
                    Object.entries(baseProps).map(
                      (
                        [
                          key,
                          {
                            title,
                            type,
                            mutability,
                            scope,
                            permissions,
                            master,
                            required,
                          },
                        ],
                        i
                      ) => {
                        if (key === 'login') {
                          return null;
                        } else {
                          return (
                            <Grid item xs={2} key={i}>
                              <Checkbox
                                aria-label="Checkbox demo"
                                name="attributes"
                                value={key}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultChecked={required}
                                disabled={required}
                              />
                              {title}
                            </Grid>
                          );
                        }
                      }
                    )
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {customProps && formik.values.appID? (
            <>
              <Grid item xs={12}>
                <h4>Custom Attributes</h4>
                <Grid container>
                  {customProps ? (
                    Object.entries(customProps).map(
                      (
                        [
                          key,
                          {
                            title,
                            type,
                            mutability,
                            scope,
                            permissions,
                            master,
                            required,
                          },
                        ],
                        i
                      ) => {
                        return (
                          <Grid item xs={2} key={i}>
                            <Checkbox
                              aria-label="Checkbox demo"
                              name="attributes"
                              value={key}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              defaultChecked={required}
                              disabled={required}
                            />
                            {title}
                          </Grid>
                        );
                      }
                    )
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid item xs={6}>
            <Button
              variant="outlined"
              type="submit"
              disabled={
                formik?.values?.attributes.length > 0 && formik.values.appID
                  ? false
                  : true
              }
            >
              {' '}
              Download Sample File
            </Button>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
    </>
  );
};

export default DownloadSampleFile;
