import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { Field, Form, Formik } from "formik";
import * as yup from 'yup'

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    border: "1px solid black",
    padding: "2%",
    position: "relative",
    marginLeft: "10%",
    marginRight: "10%",
  },
  GridMain: {
    padding: "2%",
  },
  GridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    margin: "0px",
  },
  GridButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
}));

function OidcApp() {
  const classes = useStyles();
  // const [WildCardAlert, setWildCardAlert] = useState(false);

  const [redirect_uris, setredirect_uris] = useState([{ redirect_uri: "" }]);
  const [postSignOutUrls, setpostSignOutUrls] = useState([
    { postSignOutUrl: "" },
  ]);
  const [BaseUrls, setBaseUrls] = useState([{ BaseUrl: "" }]);

  const CancelButton = (index) => {};
  const initialValues = {
    AppIntegrationName : ""
  };

  const validationSchema = yup.object({
      AppIntegrationName: yup.string().required("App Name Is Required")
  })

  const onSubmit = (values) => {
    console.log({values})
  }

  return (
    <MainCard title="OIDC APP CREATION">
      <Typography variant="h2" style={{ marginLeft: "10%", padding: "1%" }}>
        New Single-Page App Integration
      </Typography>
      <Paper className={classes.paperStyle}>
        <Typography variant="h3"> General Settings</Typography>
        <Formik
         initialValues={ initialValues }
         validationSchema= { validationSchema }
         onSubmit = {onSubmit} 
        >
          {(props) => (
            <Form>
              <Grid container className={classes.GridMain}>
                <Grid item xs={6} className={classes.GridItem}>
                  <Typography variant="h5">App Integration Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    label= "AppIntegrationName"
                    name= "AppIntegrationName"
                    value= {props.values.AppIntegrationName}
                    onChange= {props.handleChange}
                    onBlur= {props.handleBlur}
                    error = {props.errors.AppIntegrationName && props.touched.AppIntegrationName && Boolean(props.errors.AppIntegrationName)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} className={classes.GridItem}>
                  <Typography variant="h5">Grant Type</Typography>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  <Stack direction="row" className={classes.GridItem}>
                    <Checkbox size="small" />
                    <Typography variant="h5"> Authorization Code </Typography>
                  </Stack>
                  <Stack direction="row" className={classes.GridItem}>
                    <Checkbox size="small" />
                    <Typography variant="h5"> Interaction Code </Typography>
                  </Stack>
                  <Stack direction="row" className={classes.GridItem}>
                    <Checkbox size="small" />
                    <Typography variant="h5"> Refresh Token </Typography>
                  </Stack>
                  <Stack direction="row" className={classes.GridItem}>
                    <Checkbox size="small" />
                    <Typography variant="h5">Implicit(hybrid) </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <hr />
              <Grid container className={classes.GridMain}>
                <Grid item xs={6} className={classes.GridItem}>
                  <Stack direction="row" className={classes.GridItem}>
                    <Typography variant="h5">Sign-in redirect URIs</Typography>
                    <Tooltip
                      title="Okta sends the authentication response and ID token for the user's sign-in request to these URIs."
                      placement="top"
                    >
                      <IconButton>
                        <HelpOutlineIcon
                          style={{ color: "grey", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" className={classes.GridItem}>
                    <Checkbox />
                    <Typography variant="h5" style={{ fontSize: "15px" }}>
                      Allow wildcard * in login URI redirect.
                    </Typography>
                  </Stack>
                  {/* {WildCardAlert ? (
                    <Alert
                      severity="warning"
                      variant="filled"
                      color="info"
                      style={{ marginTop: "1%" }}
                    >
                      <AlertTitle>Potential security risk</AlertTitle>
                      <strong>
                        Including wildcards in the login redirect URI can be
                        insecure. Potential risks include possible attacks to
                        gain access to authorization codes or utilize open
                        redirects.
                      </strong>
                    </Alert>
                  ) : null} */}
                  {redirect_uris && redirect_uris.length > 0
                    ? redirect_uris.map((item, index) => (
                        <Stack
                          direction="row"
                          className={classes.GridItem}
                          style={{ marginTop: "2%" }}
                        >
                          <TextField
                            fullWidth
                            name={`redirect_uris[${index}].redirect_uri`}
                          />
                          <IconButton onClick={() => CancelButton(index)}>
                            <ClearIcon fontSize="large" />
                          </IconButton>
                        </Stack>
                      ))
                    : null}
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    style={{ marginTop: "2%" }}
                    onClick={() =>
                      setredirect_uris([...redirect_uris, { redirect_uri: "" }])
                    }
                  >
                    ADD URL
                  </Button>
                </Grid>
              </Grid>
              <hr />
              <Grid container className={classes.GridMain}>
                <Grid item xs={6} className={classes.GridItem}>
                  <Stack direction="row" className={classes.GridItem}>
                    <Typography variant="h5">Sign-out redirect URIs</Typography>
                    <Tooltip
                      title="After your application contacts Okta to close the user session, Okta redirects the user to one of these URIs."
                      placement="top"
                    >
                      <IconButton>
                        <HelpOutlineIcon
                          style={{ color: "grey", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  {postSignOutUrls && postSignOutUrls.length > 0
                    ? postSignOutUrls.map((item, index) => (
                        <Stack
                          direction="row"
                          className={classes.GridItem}
                          style={{ marginTop: "2%" }}
                        >
                          <TextField
                            fullWidth
                            name={`postSignOutUrls[${index}].postSignOutUrl`}
                          />
                          <IconButton onClick={() => CancelButton(index)}>
                            <ClearIcon fontSize="large" />
                          </IconButton>
                        </Stack>
                      ))
                    : null}
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    style={{ marginTop: "2%" }}
                    onClick={() =>
                      setpostSignOutUrls([
                        ...postSignOutUrls,
                        { postSignOutUrl: "" },
                      ])
                    }
                  >
                    ADD URL
                  </Button>
                </Grid>
              </Grid>
              <hr />
              <Typography variant="h4" style={{ margin: "2%" }}>
                Trusted Origins
              </Typography>
              <Grid container className={classes.GridMain}>
                <Grid item xs={6} className={classes.GridItem}>
                  <Stack direction="row" className={classes.GridItem}>
                    <Typography variant="h5">Base URIs (Optional)</Typography>
                    <Tooltip
                      title="Required if you plan to self-host the Okta Sign-In Widget. With a Trusted Origin set, the Sign-In Widget can make calls to the authentication API from this domain."
                      placement="top"
                    >
                      <IconButton>
                        <HelpOutlineIcon
                          style={{ color: "grey", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  {BaseUrls && BaseUrls.length > 0
                    ? BaseUrls.map((item, index) => (
                        <Stack
                          direction="row"
                          className={classes.GridItem}
                          style={{ marginTop: "2%" }}
                        >
                          <TextField
                            fullWidth
                            name={`BaseUrls[${index}].BaseUrl`}
                          />
                          <IconButton onClick={() => CancelButton(index)}>
                            <ClearIcon fontSize="large" />
                          </IconButton>
                        </Stack>
                      ))
                    : null}
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    style={{ marginTop: "2%" }}
                    onClick={() => setBaseUrls([...BaseUrls, { BaseUrl: "" }])}
                  >
                    ADD URL
                  </Button>
                </Grid>
              </Grid>

              <hr />
              <Typography variant="h4" style={{ margin: "2%" }}>
                Assignments
              </Typography>
              <Grid container className={classes.GridMain}>
                <Grid item xs={6} className={classes.GridItem}>
                  <Stack
                    direction="row"
                    className={classes.GridItem}
                    style={{ marginLeft: "4%" }}
                  >
                    <Typography variant="h5">Controlled access</Typography>
                    <Tooltip
                      title="Select whether to assign the app integration to everyone in your org, only selected group(s), or skip assignment until after app creation."
                      placement="top"
                    >
                      <IconButton>
                        <HelpOutlineIcon
                          style={{ color: "grey", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Allow everyone in your organization to access"
                        control={<Radio />}
                        label="Allow everyone in your organization to access"
                      />
                      <FormControlLabel
                        value="Limit access to selected groups"
                        control={<Radio />}
                        label="Limit access to selected groups"
                      />
                      <FormControlLabel
                        value="Skip group assignment for now"
                        control={<Radio />}
                        label="Skip group assignment for now"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container className={classes.GridMain}>
                <Grid item xs={12}>
                  <Stack direction="row" className={classes.GridButtons}>
                    <Button
                      variant="contained"
                      style={{ marginTop: "2%" }}
                      size="large"
                    >
                      Save
                    </Button>
                    <Button
                      style={{ marginTop: "2%", marginLeft: "2%" }}
                      size="large"
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </MainCard>
  );
}

export default OidcApp;
