import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import LoginCallbackRoutes from './LoginCallbackRoutes';
import PublicRoutes from './PublicRoutes'

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    
    return useRoutes([LoginCallbackRoutes,AuthenticationRoutes,MainRoutes,PublicRoutes], config.basename);
}
